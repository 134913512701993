import { jsonp, ajax, cookie } from '@ifeng-fe/ui_base';
import { formatImage, formatUrl } from '@ifeng-fe/public_method';
const carBaseUrl = '//api.auto.ifeng.com'; // '//10.90.3.81:8080';

// apiUrl为webpack注入的全局变量
/* eslint-disable no-undef */
let apiBaseUrl = apiUrl;

/* eslint-enable no-undef */
if (typeof window === 'object' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

const md5 = require('md5');

const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    /* eslint-disable */
    while (typeof window === 'object' && window[jsonpCallbackName]) {
        /* eslint-enable */
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

// 获取文章评论数
const getCommentCount = async url => {
    return await jsonp('//comment.ifeng.com/get.php', {
        data: {
            job: 4,
            format: 'js',
            callback: 'getAllComment1',
            docurl: url.join('|'),
        },
        jsonCallback: 'getAllComment1',
        cache: false,
    });
};

// 根据自媒体账号 id 获取自媒体账号头像
const getwemediaEAccountImg = async wemediaEAccountId => {
    const data = await jsonp(
        `${apiBaseUrl}/finance/index/getwemediaEAccountImg/${wemediaEAccountId}/getwemediaEAccountImg`,
        {
            jsonpCallback: 'getwemediaEAccountImg',
            cache: false,
        },
    );

    let result = {};

    if (data.code === 0) {
        result = {
            img: data.data.headImage && data.data.headImage !== '' ? formatImage(data.data.headImage, 50, 50) : '',
            weMediaName: data.data.weMediaName ? data.data.weMediaName : '',
            description: data.data.description ? data.data.description : '',
        };
    }

    return result;
};

// 获取品牌
const getBrandandSerialList = async () => {
    const data = await jsonp(`${carBaseUrl}/api/getBrandandSerialList.do`);

    return data;
};

// 获取车型推荐
const getTypeRecommen = async type => {
    const data = await jsonp(`http://api.auto.ifeng.com/api/getHomePageSerialRecomm4PC.do?type=${type}`, {
        jsonp: 'callbackparam',
        jsonpCallback: 'getTypeRecommen',
    });

    return data;
};
// 获取焦点图第四帧地方站推广
const getMcmsSwiper = async city => {
    const data = await jsonp(`//api.auto.ifeng.com/cms/api/mcms/carjdt?city=${city}`, {
        jsonpCallback: 'callbackfun',
    });

    return data.data;
};

// 获取询价城市
const getXunjiaCitys = async () => {
    const data = await jsonp('//api.auto.ifeng.com/cms/api/cityInfo_new?pro_city=1', {
        jsonpCallback: 'tempfun',
    });

    return data.data[0].pro_city;
};
// 获取快捷询价
const getFastXunjia = async serialId => {
    const data = await jsonp(
        `//api.auto.ifeng.com/api/getSerialRecommen.do?serialId=${serialId}&count=4&key='1366879516497_akt7i54447'`,
        {
            jsonp: 'callbackparam',
            jsonpCallback: 'callbackfun',
        },
    );

    return data;
};

// 获取询价新闻
const getXunjiaNews = async (serialId, cityid) => {
    const data = await jsonp(
        `//api.auto.ifeng.com/app/api/city/xunjia_alert_news.php?s_id=${serialId}&citycode=${cityid}`,
        {
            jsonp: 'callback',
            jsonpCallback: 'callbackfun',
        },
    );

    return data;
};

//  * 获取热车排行数据
//  */
const getHotCarList = async () => {
    const data = await jsonp('http://api.auto.ifeng.com/api/getHotRank.do', {
        jsonp: 'callbackparam',
        jsonpCallback: 'getHotCarList',
        cache: false,
    });

    return data;
};

//
const getPriceInterval = async (serialId, cityid) => {
    const data = await jsonp(
        `//dealer.auto.ifeng.com/api/getPriceIntervalBySerialIdAndCity?serialId=${serialId}&cityId=${cityid}`,
        {
            jsonp: 'callback',
            jsonpCallback: 'callbackfun',
        },
    );

    return data;
};

/**
 * 获取车型图片推荐数据
 */
const getTypeImgCarList = async () => {
    const data = await jsonp('http://api.auto.ifeng.com/api/getDiffCarInfo.do', {
        jsonp: 'callbackparam',
        jsonpCallback: 'getTypeImgCarList',
        cache: false,
    });

    return data;
};
const getMessageHander = async sendData => {
    const data = await jsonp('http://dealer.auto.ifeng.com/sms/messageHander', {
        jsonp: 'callbackparam',
        jsonpCallback: 'callbackfun',
        data: {
            'messageInfo.userName': sendData.userName,
            'messageInfo.mobile': sendData.mobile,
            'messageInfo.cityName': sendData.cityName,
            'messageInfo.province': sendData.province,
            'messageInfo.city': sendData.city,
            'messageInfo.brandId': sendData.brandId,
            'messageInfo.serialId': sendData.serialId,
            'messageInfo.carId': sendData.carId,
            'messageInfo.carName': sendData.carName,
            'messageInfo.brandName': sendData.brandName,
            'messageInfo.serialName': sendData.serialName,
            'messageInfo.contactType': sendData.contactType,
            'messageInfo.gender': sendData.gender,
        },
    });

    return data;
};

// 获取车展数据
const getChenZhan = async serialId => {
    const data = await jsonp('//api.auto.ifeng.com/api/getCarHotSpecial.do');

    const result = data.data.map(item => ({
        image: formatImage(item.image, 170, 114),
        url: formatUrl(item.url),
        name: item.name,
    }));

    return result;
};
// 地方站车市
const getCheShi = async (citypy, typeid) => {
    const data = await jsonp(`//api.auto.ifeng.com/cms/api/CarMarket?city=${citypy}&type=${typeid}`);

    return data.data;
};

// 热车排行
const getHotCarRank = async () => {
    const data = await jsonp('//api.auto.ifeng.com/api/getHotRank.do', {
        jsonp: 'callbackparam',
        jsonpCallback: 'getHotCarRank',
    });

    return data;
};

/**
 * 获取地方站车市信息
 */
const getLocalCarInfoList = async city => {
    const data = await jsonp(`https://api.auto.ifeng.com/cms/api/CarMarket?city=${city}&type=1`, {
        jsonpCallback: 'getLocalCarInfoList',
        cache: false,
    });

    return data;
};

/**
 * 获取地方站车文章
 */
const getLocalCarArticle = async city => {
    const data = await jsonp(`https://api.auto.ifeng.com/cms/api/auto_news_ipflow_v2?city=${city}&size=25`, {
        jsonpCallback: 'getLocalCarArticle',
        cache: false,
    });

    return data;
};

/**
 * 获取大凤号作者信息
 */
const getLargeWindAuthor = async () => {
    const data = await jsonp('//api-newcms.auto.ifeng.com/getHautorsNewList?page=1&num=10', {
        jsonpCallback: 'getLargeWindAuthor',
        cache: false,
    });

    return data;
};

/**
 * 获取上市新车信息
 */
const getNewCarHomeOnline = async () => {
    const data = await jsonp('//api-newcms.auto.ifeng.com/getNewCarHomeOnline', {
        jsonpCallback: 'getNewCarHomeOnline',
        cache: false,
    });

    return data;
};

/**
 * 获取团购信息
 */
const getGroupBuying = async cityid => {
    const data = await jsonp(
        `http://apps.auto.ifeng.com/ecarwap/api/groupon/getGroupontoMedia?gstatus=0&pc=3&city=${cityid}`,
        {
            jsonpCallback: 'getGroupBuying',
            cache: false,
        },
    );

    return data;
};

// 热图排行
const getHotPicRank = async () => {
    const data = await jsonp('//api.auto.ifeng.com/api/getPicRank.do', {
        jsonp: 'callbackparam',
        jsonpCallback: 'getHotPicRank',
    });

    return data;
};

/**
 * 获取精选美图信息
 */
const getWonferfulPic = async () => {
    const data = await jsonp('http://api.auto.ifeng.com/api/getDiffCarInfo.do', {
        jsonp: 'callbackparam',
        jsonpCallback: 'getWonferfulPic',
        cache: false,
    });

    return data;
};
// // 获取城市详情
const getCityInfo = async () => {
    const data = await jsonp('//api.auto.ifeng.com/cms/api/cityinfo', {
        jsonpCallback: 'callback',
        cache: false,
    });
    const cityArr = [
        ['a'],
        ['b'],
        ['c'],
        ['d'],
        ['e'],
        ['f'],
        ['g'],
        ['h'],
        ['j'],
        ['k'],
        ['l'],
        ['m'],
        ['n'],
        ['p'],
        ['q'],
        ['s'],
        ['t'],
        ['w'],
        ['x'],
        ['y'],
        ['z'],
    ];
    const cityLetter = [];

    for (const item of cityArr) {
        cityLetter.push(item[0].toUpperCase());
    }

    for (const item of data) {
        for (const cityIndex of cityArr) {
            // cityLetter.push(cityIndex[0]);
            if (cityIndex[0].toLowerCase() === item.pinyin.charAt(0).toLowerCase()) {
                cityIndex.push(`${item.name} , ${item.pinyin}, ${item.citycode}`);
            }
        }
    }

    return {
        cityArr,
        cityLetter,
    };
};
/**
 * 获取汽车视频信息
 */
const getAutoVideo = async () => {
    const data = await jsonp('https://apps.auto.ifeng.com/ecarwap/api/video/find', {
        jsonpCallback: 'getAutoVideo',
        cache: false,
    });

    return data;
};

/**
 * 获取快捷选车信息
 */
const getQuickSelectCar = async obj => {
    const data = await jsonp(
        `//api.auto.ifeng.com/api/condSelectCarInfo.do?guideprice=${obj.guideprice}&country=${obj.country}&level=${
            obj.level
        }&energy=${obj.energy}&biansu=${obj.biansu}&page=1&pagesize=10`,
        {
            jsonp: 'callbackparam',
            jsonpCallback: 'getQuickSelectCar',
            cache: false,
        },
    );

    return data;
};

/**
 * 获取热销榜信息
 */
const getHotSale = async (year, month, id) => {
    const data = await jsonp(
        `//apps.auto.ifeng.com/ecar/baojia/api/serial/1/hotsalelist?appkey=5b04981f00000000&year=${year}&month=${month}&seriallevel=${id}&page=1&pagesize=8`,
        {
            jsonpCallback: 'getHotRank',
            cache: false,
        },
    );

    return data;
};

// 点击更多，获取信息流
const getColumnInfo2 = async (id, time, size, columnId) => {
    return await jsonp(
        `//shankapi.ifeng.com/shanklist/_/getColumnInfo2/${id}/${time}/${size}/${columnId}/getColumnInfoCallback`,
        {
            jsonpCallback: 'getColumnInfoCallback',
        },
    );
};

const getIp = async () => {
    cookie.set('auto_iploc', ''); // 清cookie
    let auto_iploc = cookie.get('auto_iploc');
    const cityData = await ajax('//s.auto.ifeng.com/locip/getLocUp');

    if (cityData) {
        const jsond = cityData;
        const ocode = parseInt(jsond.code);
        const ip = jsond.ip;
        let iploc_name = jsond.name;
        let ifengcookie = '';

        if (
            jsond === null ||
            jsond === undefined ||
            jsond.length < 1 ||
            jsond.code === undefined ||
            jsond.code === null ||
            jsond.code === ''
        ) {
            ifengcookie = '110000';
            iploc_name = '北京';
            alert('当前城市暂时无法识别，请手动切换！');
        } else if (ocode < 0) {
            ifengcookie = '110000';
            iploc_name = '北京';
            alert('当前城市暂时无法识别，请手动切换！');
        } else {
            auto_iploc = ifengcookie = jsond.code;
        }
        auto_iploc = ifengcookie;
        // 就近原则
        const iploc_code = auto_iploc.replace(/cn/gi, '');
        let cityname = '';
        let citycode = '';
        let citypinyin = '';
        const cityAllData = await jsonp('//api.auto.ifeng.com/cms/api/cityInfo_new', {
            data: {
                match: 1,
                citycode: iploc_code ? iploc_code : '110000',
            },
        });

        if (cityAllData) {
            const data = cityAllData;
            let jjcode = '';

            if (data.status === 0) {
                jjcode = '110000';
                cityname = '北京';
                citycode = '110000';
                citypinyin = 'beijing';
            } else if (data.data && data.data[0].match.length > 0) {
                jjcode = data.data[0].match[0].match_citycode; // 就近原则城市编码
                cityname = data.data[0].match[0].match_name;
                citycode = data.data[0].match[0].match_citycode;
                citypinyin = data.data[0].match[0].match_pinyin;
            } else {
                jjcode = '110000';
                cityname = '北京';
                citycode = '110000';
                citypinyin = 'beijing';
            }

            citycode = citycode ? citycode : 110000;
            cityname = cityname ? cityname : '北京';
            citypinyin = citypinyin ? citypinyin : 'beijing';
            // allData.curCity = {
            //     citycode: 340800,
            //     cityname: '安庆',
            //     citypinyin: 'anqing',
            // };
            const exp = new Date();

            exp.setTime(exp.getTime() + 120 * 60 * 1000);
            cookie.set('auto_iploc', jjcode, {
                expires: exp,
            });

            return {
                citycode,
                cityname,
                citypinyin,
            };
        }
    }
};

export {
    getCommentCount,
    getwemediaEAccountImg,
    getBrandandSerialList,
    getTypeRecommen,
    getMcmsSwiper,
    getXunjiaCitys,
    getFastXunjia,
    getXunjiaNews,
    getPriceInterval,
    getMessageHander,
    getChenZhan,
    getCheShi,
    getHotCarRank,
    getHotPicRank,
    getCityInfo,
    getHotCarList,
    getTypeImgCarList,
    getLocalCarInfoList,
    getLocalCarArticle,
    getLargeWindAuthor,
    getNewCarHomeOnline,
    getGroupBuying,
    getWonferfulPic,
    getAutoVideo,
    getQuickSelectCar,
    getHotSale,
    getColumnInfo2,
    getIp,
};
