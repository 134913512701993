/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabsNews from './tabsNews';

class Info extends React.Component {
    static propTypes = {
        content: PropTypes.object,
        curCity: PropTypes.object,
    };

    async componentDidMount() {}

    /**
     * 渲染组件
     */
    render() {
        const { content, curCity } = this.props;
        const newsstream = content.infoStream;
        const adData = {
            carImageAd: content.carImageAd,
            trigger01Ad: content.trigger01Ad,
            trigger02Ad: content.trigger02Ad,
            infoAd: content.infoAd,
            infoAd2: content.infoAd2,
            infoAd3: content.infoAd3,
            infoYg: content.infoYg,
            informationtext01: content.informationtext01,
            informationtext02: content.informationtext02,
            informationtext03: content.informationtext03,
            informationtext04: content.informationtext04,
        };

        return (
            <div className={styles.info}>
                <TabsNews
                    content={newsstream}
                    ad={adData}
                    curCity={curCity}
                    newsstreamTxtEdit={content.newsstreamTxtEdit}
                    newsstreamSecondEdit={content.newsstreamSecondEdit}
                    newsstreamFourthEdit={content.newsstreamFourthEdit}
                    newsstreamSixthEdit={content.newsstreamSixthEdit}
                />
            </div>
        );
    }
}

export default errorBoundary(Info);
