import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import NewsContent from './newsContent';
import AutoVideo from './autoVideo';
import Info from './info';
import SwiperPic from './swiperPic';
import TxtInfo from './txtInfo';
import TxtInfoFlow from './txtInfoFlow';
import Chip from 'Chip';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const globalAutoTalkIdList = content.globalAutoTalk.map(item => item.base62Id);
        const autoVideoIdListData = content.autoVideoList.filter(item => !globalAutoTalkIdList.includes(item.base62Id));

        return (
            <div className={styles.col}>
                <SwiperPic content={content.swiperContent} swiperData={content.swiperData} curCity={content.curCity} />

                {/* 文字链 */}
                <Chip
                    id="225030"
                    type="recommend"
                    title="首页焦点图下方推荐位1-2"
                    groupName="content"
                    content={content.txtInfo}>
                    <TxtInfo ad={content.txtInfoAd} />
                </Chip>
                <TxtInfoFlow content={content.txtInfo2} ad2={content.txtInfoAd2} />
                {/* 文字链 */}
                <div className={styles.banner_ad_div}>
                    <Ad className={styles.banner_ad} content={content.banner01Ad} />
                </div>
                {content.curCity ? (
                    <div className={styles.news111} id="module1">
                        <div className={styles.col_L}>
                            <Info content={content.info} curCity={content.curCity} />
                        </div>
                        <NewsContent
                            content={content.rightContent}
                            curCity={content.curCity}
                            viewPoints={content.viewPoints}
                            hotSubject={content.hotSubject}
                        />
                    </div>
                ) : (
                    ''
                )}
                <div className={styles.banner_ad_div}>
                    <Ad className={styles.banner_ad} content={content.info.banner03Ad} />
                </div>
                <div style={{ overflow: 'hidden' }}>
                    <AutoVideo content={content.globalAutoTalk} title="全球车视野" />
                </div>

                <div className={styles.banner_ad_div}>
                    <Ad className={styles.banner_ad} content={content.info.banner04Ad} />
                </div>
                <div style={{ overflow: 'hidden' }} id="module3">
                    <AutoVideo content={autoVideoIdListData.slice(0, 5)} title="汽车视频" />
                </div>
                {/* <div className={styles.banner_ad_div}>
                    <Ad className={styles.banner_ad} content={content.info.banner05Ad} />
                </div> */}

                <div className={styles.banner_ad_div}>
                    <Ad className={styles.banner_ad} content={content.info.banner06Ad} />
                </div>
            </div>
        );
    }
}

export default Content;
