/* eslint-disable eqeqeq */
/**
 * 新闻流
 * props:
 *   content { Array }               : 信息流的数据
 *   isDisplaySource { boolean }     : 是否显示新闻来源
 *   columnId { number }             : 碎片id
 *   isEnd { boolean }               : 是否已显示全部，true 已显示全部，false 还有数据
 *   repeatedID { Array<string> }    : 信息流内用于排重的id数组
 *   isScrollLoadingData { boolean } : 是否滚动加载
 *   isPic { boolean }               : 是否为大图模式
 *   isDisplayLoadingMore { boolean }: 是否显示加载更多
 *   ad { Array<Object> }            : 信息流内广告
 */

import { addEventListener, Event as UiBaseEvent } from '@ifeng-fe/ui_base';
import { formatImage, formatUrl } from '@ifeng-fe/public_method';
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import EnqueryAlert from '../../../../components/enqueryAlert';
import Chip from 'Chip';
import {
    getCommentCount,
    getColumnInfo2,
    getTypeImgCarList,
    getHotCarList,
    getLocalCarInfoList,
} from '../../../../../../services/api';
import { formatTime } from '../../../../../../utils/formatTime';
import { rel as relText } from '../../../../../../utils/rel';
import { classnames } from '../../../../../../utils/classnames';
import { handleAd } from '../../../../../../utils/handleAd';
import Ad from '@ifeng-fe/ui_pc_ad';

const NewsStream = errorBoundary(
    class extends PureComponent {
        static propTypes = {
            content: PropTypes.array,
            isDisplaySource: PropTypes.bool,
            isEnd: PropTypes.bool,
            repeatedID: PropTypes.arrayOf(PropTypes.string),
            isScrollLoadingData: PropTypes.bool,
            isPic: PropTypes.bool,
            isDisplayLoadingMore: PropTypes.bool,
            ad: PropTypes.object,
            // eslint-disable-next-line react/no-unused-prop-types
            typeId: PropTypes.number,
            curCity: PropTypes.object,
            moreHref: PropTypes.string,
            newsstreamTxtEdit: PropTypes.array,
            newsstreamSecondEdit: PropTypes.array,
            newsstreamFourthEdit: PropTypes.array,
            newsstreamSixthEdit: PropTypes.array,
        };
        static defaultProps = {
            isDisplaySource: true,
            isEnd: false,
            repeatedID: [],
            isScrollLoadingData: false,
            isPic: false,
            isDisplayLoadingMore: true,
        };

        constructor(...argv) {
            super(...argv);

            this.event = new UiBaseEvent();
            this.list = createRef();
            this.countMapCache = {}; // 文章评论数，用于判断评论的数据是否已经读取
            this.countMap = {}; // 文章评论数，渲染list后清空
            this.lastItem = null; // 最后一条数据
            this.position = null; // 记录滚动的位置
            this.state = {
                content: this.props.content, // 文章列表
                isEnd: this.props.isEnd, // 是否结束
                isLoading: false, // 是否加载中
                hotcarData: [], // 热车排行数据
                recommendcarData: {}, // 车型图片推荐数据
                localcarData: {}, // 地方站车市信息
                isEnqueryAlertShow: false,
                carSeriesData: {},
            };

            if (this.props.isScrollLoadingData) {
                this.unlistenerHandleWindowScroll = addEventListener(window, 'scroll', this.handleWindowScroll);
            }
        }
        getChildData = res => {
            this.setState({
                isEnqueryAlertShow: res,
            });
        };
        handleClick = list => {
            const carSeriesData = {
                brand: list.brandId,
                serial: list.serialId,
            };

            this.setState({
                carSeriesData,
                isEnqueryAlertShow: true,
            });
        };

        ref = React.createRef();

        async componentDidMount() {
            await this.getCount();
            // const hotcarData = await getHotCarList();
            // const recommendcarData = await getTypeImgCarList();
            // const localcarData = await getLocalCarInfoList(
            //     this.props.curCity && this.props.curCity.citypinyin ? this.props.curCity.citypinyin : 'beijing',
            // );

            // this.setState({
            //     hotcarData,
            //     recommendcarData,
            //     localcarData,
            // });
            // 插入广告

            const { ad } = this.props;

            const callbackFn = await handleAd(ad.carImageAd);
            const callbackFn2 = await handleAd(ad.trigger01Ad);
            const callbackFn3 = await handleAd(ad.trigger02Ad);
            const callbackFn4 = await handleAd(ad.infoAd);
            const callbackFn5 = await handleAd(ad.infoAd2);
            const callbackFn6 = await handleAd(ad.infoAd3);
            const callbackFn7 = await handleAd(ad.informationtext01);
            const callbackFn8 = await handleAd(ad.informationtext02);
            const callbackFn9 = await handleAd(ad.informationtext03);
            const callbackFn10 = await handleAd(ad.informationtext04);

            try {
                callbackFn(this.ref.current, ad.carImageAd.data);
            } catch (e) {
                console.error(e);
            }
            callbackFn2(this.ref.current, ad.trigger01Ad.data);
            callbackFn3(this.ref.current, ad.trigger02Ad.data);
            callbackFn4(this.ref.current, ad.infoAd.data);
            callbackFn5(this.ref.current, ad.infoAd2.data);
            callbackFn6(this.ref.current, ad.infoAd3.data);
            callbackFn7(this.ref.current, ad.informationtext01.data);
            callbackFn8(this.ref.current, ad.informationtext02.data);
            callbackFn9(this.ref.current, ad.informationtext03.data);
            callbackFn10(this.ref.current, ad.informationtext04.data);
        }

        insert = (insertArr, replaceArr, adFn) => {
            if (adFn && typeof adFn === 'function') {
                const { content } = this.state;

                const infoData = [...content];
                const refs = [];

                insertArr.forEach(item => {
                    const ref = React.createRef();

                    refs.push({ index: item, ref });

                    infoData.splice(item, 0, { type: 'ad', ref });
                });

                replaceArr.forEach(item => {
                    const ref = React.createRef();

                    refs.push({ index: item, ref });

                    infoData.splice(item, 1, { type: 'ad', ref });
                });

                this.setState({ content: infoData }, () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                });
            }
        };
        handleWindowScroll = () => {
            if (this.state.isLoading === false) {
                const scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                const old = this.position;

                this.position = scroll;

                // 判断滚动条位置
                if (old !== null && this.position !== null && old >= scroll) return false;

                const current = this.list.current;
                const top = current.offsetTop + current.clientHeight;

                if (scroll + (window.innerHeight || document.documentElement.clientHeight) >= top - 50) {
                    this.handleGetColumnInfoClick();
                }
            }
        };
        // 获取评论列表
        async getCount() {
            try {
                // 生成评论地址的数组
                const urlArr = [];
                const keys = [];

                for (const key in this.countMap) {
                    keys.push(key);
                    urlArr.push(this.countMap[key].commentUrl);
                }

                const data = await getCommentCount(urlArr);

                for (let i = 0, j = data.length; i < j; i++) {
                    const item = data[i];

                    this.countMap[keys[i]].allcount = item.allcount;
                }

                this.setState({
                    content: this.state.content,
                });
            } catch (err) {
                console.error(err);
            }
        }
        // 加载新数据流的评论
        countCallback() {
            this.getCount();
            if (this.props.isScrollLoadingData && this.state.isEnd === true) {
                this.unlistenerHandleWindowScroll();
            }
        }
        // 加载更多
        handleGetColumnInfoClick = async () => {
            this.setState({
                isLoading: true,
            });

            try {
                const time = new Date(this.lastItem.newsTime).getTime();

                const { data } = await getColumnInfo2(this.lastItem.id, time, 20, 150130);

                this.setState(
                    {
                        content: this.state.content.concat(data.newsstream),
                        isEnd: data.isEnd,
                        isLoading: false,
                    },
                    this.countCallback,
                );
            } catch (err) {
                console.error(err);
                this.setState({
                    isLoading: false,
                });
            }
        };
        // 判断为3图模式
        isThreeImage = item => item.thumbnails && item.thumbnails !== '' && item.thumbnails.image.length >= 3;
        // 判断为1图模式
        isOneImage = item =>
            item.thumbnails &&
            item.thumbnails !== '' &&
            item.thumbnails.image.length < 3 &&
            item.thumbnails.image.length > 0;
        // 获取image的url
        getImageUrl(item) {
            if ('picUrl' in item) {
                return item.picUrl;
            } else {
                return item.url;
            }
        }
        // 3图模式渲染
        threeImageItemView(item, index, url, pinglunUrl) {
            const image = item.thumbnails.image;
            const typeId = this.props.typeId;

            return (
                <li
                    id={`imagetext_${typeId}_${index}`}
                    key={`${item.id}_${item.creator}_${item.newsTime}`}
                    className={classnames(styles.news_item_3_image, 'clearfix', 'news_item')}
                    data-id={item.id}>
                    <h2 className={`${styles.news_item_title} ${styles.mb16}`}>
                        <a href={url} title={item.title} target="_blank" rel={relText}>
                            {item.title}
                        </a>
                    </h2>
                    <a
                        className={`${styles.image_link_3} ${styles.mr11}`}
                        href={url}
                        title={item.title}
                        target="_blank"
                        rel={relText}>
                        <img src={formatImage(this.getImageUrl(image[0]), 203, 130)} />
                    </a>
                    <a
                        className={`${styles.image_link_3} ${styles.mr11}`}
                        href={url}
                        title={item.title}
                        target="_blank"
                        rel={relText}>
                        <img src={formatImage(this.getImageUrl(image[1]), 203, 130)} />
                    </a>
                    <a className={styles.image_link_3} href={url} title={item.title} target="_blank" rel={relText}>
                        <img src={formatImage(this.getImageUrl(image[2]), 203, 130)} />
                    </a>
                    <div className={`${styles.top_11} ${styles.news_item_infor} clearfix`}>
                        {// 显示来源
                        this.props.isDisplaySource && item.source && item.source !== '' ? (
                            <span className={`${styles.mr10} ${styles.text}`}>{item.source}</span>
                        ) : null}
                        {item.newsTime ? <time className={styles.text}>{formatTime(item.newsTime)}</time> : ''}
                        {item.commentUrl ? (
                            <a
                                className={classnames(styles.ly, 'ly')}
                                id={`count${item.id}`}
                                href={pinglunUrl}
                                target="_blank"
                                rel={relText}>
                                {item.allcount || 0}
                            </a>
                        ) : (
                            ' '
                        )}
                    </div>
                </li>
            );
        }
        // 1图模式渲染
        oneImageItemView(item, index, url, pinglunUrl) {
            const image = item.thumbnails.image;
            const typeId = this.props.typeId;

            return (
                <li
                    id={`imagetext_${typeId}_${index}`}
                    key={`${item.id}_${item.creator}_${item.newsTime}`}
                    className={classnames(styles.news_item_has_image, 'clearfix', 'news_item')}
                    data-id={item.id}>
                    <a className={styles.image_link} href={url} title={item.title} target="_blank" rel={relText}>
                        {// 是否为视频
                        item.videoCount ? <div className={styles.play} /> : null}
                        {// 是否为图集
                        item.type === 'slide' ? <span className={styles.tuji}>图集</span> : null}
                        <img src={formatImage(this.getImageUrl(image[0]), 144, 96)} title={item.title} />
                    </a>
                    <div className={styles.news_item_infor}>
                        <h2 className={`${styles.news_item_title} `}>
                            <a href={url} title={item.title} target="_blank" rel={relText}>
                                {item.title}
                            </a>
                        </h2>
                        <div className={`${styles.time}  clearfix`}>
                            {// 显示来源
                            this.props.isDisplaySource && item.source && item.source !== '' ? (
                                <span className={`${styles.mr10} ${styles.text}`}>{item.source}</span>
                            ) : null}
                            {item.newsTime ? <time className={styles.text}>{formatTime(item.newsTime)}</time> : ''}
                            {item.commentUrl ? (
                                <a
                                    className={classnames(styles.ly, 'ly')}
                                    id={`count${item.id}`}
                                    href={pinglunUrl}
                                    target="_blank"
                                    rel={relText}>
                                    {item.allcount || 0}
                                </a>
                            ) : (
                                ' '
                            )}
                        </div>
                    </div>
                </li>
            );
        }
        // 无图模式
        noImageItemView(item, index, url, pinglunUrl) {
            const typeId = this.props.typeId;

            return (
                <li
                    id={`imagetext_${typeId}_${index}`}
                    key={`${item.id}_${item.creator}_${item.newsTime}`}
                    className={classnames(styles.news_item_no_image, 'clearfix', 'news_item')}
                    data-id={item.id}>
                    <div className={styles.news_item_infor}>
                        <h2 className={`${styles.news_item_title} ${styles.mb34}`}>
                            <a href={url} title={item.title} target="_blank" rel={relText}>
                                {item.title}
                            </a>
                        </h2>
                        <div className="clearfix">
                            {// 显示来源
                            this.props.isDisplaySource && item.source && item.source !== '' ? (
                                <span className={`${styles.mr10} ${styles.text}`}>{item.source}</span>
                            ) : null}
                            {item.newsTime ? <time className={styles.text}>{formatTime(item.newsTime)}</time> : ''}
                            {item.commentUrl ? (
                                <a
                                    className={classnames(styles.ly, 'ly')}
                                    id={`count${item.id}`}
                                    href={pinglunUrl}
                                    target="_blank"
                                    rel={relText}>
                                    {item.allcount || 0}
                                </a>
                            ) : (
                                ' '
                            )}
                        </div>
                    </div>
                </li>
            );
        }
        // 大图模式
        bigImageItemView(item, index, url, pinglunUrl) {
            const image = item.thumbnails.image;
            const typeId = this.props.typeId;

            return (
                <li
                    id={`imagetext_${typeId}_${index}`}
                    key={`${item.id}_${item.creator}_${item.newsTime}`}
                    className={classnames(styles.news_item_big_image, 'news_item')}
                    data-id={item.id}>
                    <h2 className={styles.news_item_big_image_title}>
                        <a href={url} title={item.title} target="_blank" rel={relText}>
                            {item.title}
                        </a>
                    </h2>
                    <a className={styles.big_image_link} href={url} title={item.title} target="_blank" rel={relText}>
                        <img src={formatImage(this.getImageUrl(image[0]), 640, 360)} />
                    </a>
                    <div className={`${styles.big_image_tools} clearfix`}>
                        {// 显示来源
                        this.props.isDisplaySource && item.source && item.source !== '' ? (
                            <span className={`${styles.mr10} ${styles.text}`}>{item.source}</span>
                        ) : null}
                        {item.newsTime ? <time className={styles.text}>{formatTime(item.newsTime)}</time> : ''}
                        {item.commentUrl ? (
                            <a
                                className={classnames(styles.ly, 'ly')}
                                id={`count${item.id}`}
                                href={pinglunUrl}
                                target="_blank"
                                rel={relText}>
                                {item.allcount || 0}
                            </a>
                        ) : (
                            ' '
                        )}
                    </div>
                </li>
            );
        }

        txtInfoView(item, url) {
            return (
                <li key={`${item.id}_${item.creator}_${item.newsTime}`} className={styles.li_txt_list}>
                    <a className={styles.a_txt_list} href={url} target="_blank">
                        {item.title2 ? item.title2 : item.title}
                    </a>
                </li>
            );
        }
        localCarInfoView(item) {
            return (
                <li className={classnames(styles.news_item_has_image, 'clearfix', 'news_item')}>
                    <a className={styles.image_link} href={item.url} target="_blank">
                        <img className={styles.image_wh} src={formatImage(item.thumb, 144, 80)} title={item.title} />
                    </a>
                    <div className={styles.news_item_infor}>
                        <h2 className={`${styles.news_item_title} ${styles.mb34}`}>
                            <a href={item.url} target="_blank">
                                {item.title}
                            </a>
                        </h2>
                    </div>
                </li>
            );
        }
        recommendCarView(list) {
            const recommend_car_url = `https://car.auto.ifeng.com/series/${list.serialId}`;
            const showCarlist = l => {
                let m = [];

                for (let i = 0; i < 4; i++) {
                    let x = i + 1;
                    let imgId = l.imgUrl.substring(l.imgUrl.lastIndexOf('/') + 1, l.imgUrl.lastIndexOf('_'));
                    let newImgId = String(Number(imgId) + i);
                    let id_2 = imgId.substring(imgId.length - 4, imgId.length - 2);
                    let newid_1 = newImgId.substring(newImgId.length - 2, newImgId.length);
                    let xin = '';

                    if (newImgId > 2884733) {
                        xin = `//x0.ifengimg.com/auto/autoimg/${newid_1}/${id_2}/${newImgId}_2.jpg`;
                    } else {
                        xin = `//a1.ifengimg.com/autoimg/${newid_1}/${id_2}/${newImgId}_2.jpg`;
                    }

                    m.push(
                        <li key={x} className={styles.li_typecar}>
                            <a href={l.openUrl} target="_blank">
                                <img src={xin} className={styles.image_zxsc} />
                            </a>
                        </li>,
                    );
                }

                return m;
            };

            return (
                <div className={styles.div_typecar}>
                    <div className={styles.art_tit_b}>
                        <div className={styles.art_tit}>
                            <a href={recommend_car_url} target="_blank">
                                {list.title}
                            </a>
                        </div>
                        <div className={styles.art_pri}>
                            <span>
                                厂商指导价：<span className={styles.pri}>{list.guidePrice}</span>
                            </span>
                            <span className={styles.inq_btn} onClick={this.handleClick.bind(this, list)}>
                                询最低价
                            </span>
                            {this.state.isEnqueryAlertShow ? (
                                <EnqueryAlert
                                    news={this}
                                    carSeriesData={this.state.carSeriesData}
                                    curCity={this.props.curCity}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                        <div className={styles.clear} />
                    </div>
                    <ul className={styles.ul_typecar}>{showCarlist(list)}</ul>
                </div>
            );
        }
        hotCarView(list) {
            return list.map((item, index) => {
                if (index < 5) {
                    const hot_car_url = `https://car.auto.ifeng.com/series/${item.serialId}`;
                    const hot_car_img = `http://a0.ifengimg.com/autoimg/serial/${item.serialId}_3.png`;

                    return (
                        <li id={`qc_trigger_banner0${index}`} key={index} className={styles.li_hotcar}>
                            <a className={styles.a_hotcar} href={hot_car_url} target="_blank">
                                <img className={styles.img_hotcar} src={hot_car_img} />
                                <span className={styles.span_hotcar}> {item.brandserialName}</span>
                            </a>
                        </li>
                    );
                }

                return '';
            });
        }

        listViewByTxt(list) {
            // 第六条开始文字链形式渲染,渲染的条数为6条。
            const startIndex = 3;
            const len = 9;
            const id = [];

            if (this.props.newsstreamTxtEdit && this.props.newsstreamTxtEdit.length > 0) {
                list.splice(len - 2, this.props.newsstreamTxtEdit.length, ...this.props.newsstreamTxtEdit);
            }

            return list.map((item, index) => {
                if (startIndex) {
                    if (index < startIndex) {
                        return '';
                    }
                }
                if (len) {
                    if (index >= len) {
                        return '';
                    }
                }

                // 和推荐位或信息流本身的id去重
                if (this.props.repeatedID.includes(item.id) || id.includes(item.id)) return null;

                // 将已渲染id加入到数组
                id.push(item.id);

                // 格式化url
                const url = formatUrl(item.url);

                return this.txtInfoView(item, url);
            });
        }

        // 渲染list
        listView(list, len, startIndex) {
            this.countMap = {};
            const endIndex = 11;
            const id = [];

            return list.map((item, index) => {
                // len为限制渲染的数量，超过设置数量则return
                // startIndex开始渲染数据的下标
                if (startIndex) {
                    if (index < startIndex) {
                        return '';
                    }
                }
                if (len) {
                    if (index >= len) {
                        return '';
                    }
                }
                if (item.type === 'ad') return <div key={index} ref={item.ref} className={styles.ad} />;

                // 获取最后一位的信息，用于加载信息流
                if (index === endIndex) {
                    this.lastItem = item;
                }

                // 和推荐位或信息流本身的id去重
                if (this.props.repeatedID.includes(item.id) || id.includes(item.id)) return null;

                // 将已渲染id加入到数组
                id.push(item.id);

                // 格式化url
                const url = formatUrl(item.url);

                // 评论
                if (!(item.id in this.countMapCache)) {
                    this.countMapCache[item.id] = item;
                    this.countMap[item.id] = item;
                }

                // 评论地址
                const query = `docUrl=${item.commentUrl}&docName=${item.title}&skey=${item.skey}&pcUrl=${item.url}`;
                const pinglunUrl = `https://gentie.ifeng.com/view.html?${query}`;

                // 大图模式
                if (this.props.isPic) return this.bigImageItemView(item, index, url, pinglunUrl);

                // 3图模式
                if (this.isThreeImage(item)) return this.threeImageItemView(item, index, url, pinglunUrl);

                // 1图模式
                if (this.isOneImage(item)) return this.oneImageItemView(item, index, url, pinglunUrl);

                // 无图模式
                return this.noImageItemView(item, index, url, pinglunUrl);
            });
        }
        // 查看更多
        moreView() {
            return (
                this.props.moreHref ? <a className={styles.more} target="_blank" href={this.props.moreHref}>
                    查看更多
                </a> : null
            );
        }
        replaceRecommondData(recommendData, replaceIndex) {
            if (this.props.typeId === 0 && recommendData && recommendData.length > 0) {
                let tmpData = recommendData[0];

                if (tmpData.type === 'video') {
                    tmpData = {
                        ...tmpData,
                        ...{
                            thumbnails: { image: [{ url: tmpData.thumbnail }] },
                            videoCount: 1,
                        },
                    };
                }
                this.state.content.splice(replaceIndex, 1, tmpData);
            }
        }
        render() {
            const { content, typeId } = this.state;

            if (content.length === 0) {
                return (
                    <div>
                        <div className={styles.more_box}>
                            <span className={styles.is_end}>暂无数据</span>
                        </div>
                    </div>
                );
            } else if (this.props.typeId == 1) {
                return (
                    <div>
                        {/* 新闻列表 */}
                        <ul ref={this.list} className={styles.news_list}>
                            {/* 信息流1-15 */}
                            {this.listView(content, 15)}
                        </ul>
                        {/* 点击查看更多 */}
                        {this.props.isDisplayLoadingMore ? (
                            <div className={styles.more_box}>{this.moreView()}</div>
                        ) : null}
                    </div>
                );
            } else if (this.props.typeId == 2) {
                return (
                    <div>
                        {/* 新闻列表 */}
                        <ul ref={this.list} className={styles.news_list}>
                            {/* 信息流1-15 */}
                            {this.listView(content, 17)}
                        </ul>
                        {/* 点击查看更多 */}
                        {this.props.isDisplayLoadingMore ? (
                            <div className={styles.more_box}>{this.moreView()}</div>
                        ) : null}
                    </div>
                );
            } else if (this.props.typeId == 0) {
                this.replaceRecommondData(this.props.newsstreamSecondEdit, 1);
                this.replaceRecommondData(this.props.newsstreamFourthEdit, 9);
                this.replaceRecommondData(this.props.newsstreamSixthEdit, 11);

                return (
                    <div ref={this.ref} style={{ position: 'relative' }}>
                        <Chip
                            id="255044"
                            type="recommend"
                            title="最新信息流第二条编辑"
                            groupName="newsstream"
                            position="absolute"
                            content={this.props.newsstreamSecondEdit}>
                            <div className={styles.newsstreamSecondEdit} />
                        </Chip>
                        <Chip
                            id="225036"
                            type="recommend"
                            title="信息流最新文字链5-6推荐位"
                            groupName="newsstream"
                            position="absolute"
                            content={this.props.newsstreamTxtEdit}>
                            <div className={styles.newsstreamTxtEdit} />
                        </Chip>
                        <Chip
                            id="255193"
                            type="recommend"
                            title="最新信息流第四条编辑"
                            groupName="newsstream"
                            position="absolute"
                            content={this.props.newsstreamFourthEdit}>
                            <div className={styles.newsstreamFourthEdit} />
                        </Chip>
                        <Chip
                            id="195087"
                            type="recommend"
                            title="最新信息流第六条编辑"
                            groupName="newsstream"
                            position="absolute"
                            content={this.props.newsstreamSixthEdit}>
                            <div className={styles.newsstreamSixthEdit} />
                        </Chip>
                        {/* 新闻列表 */}
                        <ul ref={this.list} className={styles.news_list}>
                            {/* 信息流1-3 */}

                            {this.listView(content, 3)}
                            {/* 文字链信息流4-9 */}

                            <div className={styles.div_txt_list}>
                                <ul id="list_txt_id" className={styles.ul_txt_list}>
                                    {this.listViewByTxt(content)}
                                </ul>
                            </div>

                            {/* 信息流10-12 */}
                            {this.listView(content, 12, 9)}

                            <div className={styles.banner_ad_div}>
                                <Ad className={styles.banner_ad} content={this.props.ad.infoYg} />
                            </div>

                            {/* 信息流12-14 */}
                            {this.listView(content, 14, 12)}

                            {/* 车型图片推荐 */}
                            {this.state.recommendcarData.newCarInfo &&
                            this.state.recommendcarData.newCarInfo.length > 0 ? (
                                <div id="recommendcarId">
                                    {this.recommendCarView(this.state.recommendcarData.newCarInfo[0])}
                                </div>
                            ) : (
                                <div />
                            )}
                            {/* 地方站文章 */}
                            {this.state.localcarData.data ? (
                                <div>{this.localCarInfoView(this.state.localcarData.data.redPrice[0])}</div>
                            ) : (
                                ''
                            )}
                            {this.listView(content, 18, 14)}
                        </ul>
                        {/* 点击查看更多 */}
                        {this.props.isDisplayLoadingMore ? (
                            <div className={styles.more_box}>{this.moreView()}</div>
                        ) : null}
                    </div>
                );
            }
        }
    },
);

export { NewsStream };
export default NewsStream;
