import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import Footer from '../../../../components/footer';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Footer 组件
 */
class BottomFooter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const { bottomAd } = content;

        const BottomAd = (
            <div key="bottomAd" className={styles.ad}>
                <NoSSR onSSR={<div className={styles.box} />}>
                    <Ad content={bottomAd} styleName={styles.box} />
                </NoSSR>
            </div>
        );
        const footer = (
            <div key="footer" className={styles.footer}>
                <Footer content={content.footer} />
            </div>
        );

        return [BottomAd, footer];
    }
}

export default errorBoundary(BottomFooter);
