import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import { addEventListener } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Header from './header';
import TopNav from '../../../components/topNav';
import Content from './content';
import BottomFooter from './footer';
import Cooperation from './cooperation';
import QrCode from './qrCode';
import BottomAffix from './bottomAffix';
import Enquery from './enquery';
import { getCityInfo, getIp } from '../../../services/api';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            cityData: [],
            cityLetter: [],
            curCity: {},
        };
        // 这边绑定是必要的，这样 `this` 才能在回调函数中使用
        // this.handleClick = this.handleClick.bind(this);
    }

    async componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
        this.unHandleClick = addEventListener(document, 'click', this.handleClick);
        try {
            // const cityData = (await getCityInfo()).cityArr;
            // const cityLetter = (await getCityInfo()).cityLetter;

            // this.setState({
            //     cityData,
            //     cityLetter,
            // });
        } catch (e) {
            console.error(e);
        }
        try {
            // const curCity = await getIp();

            // this.setState({
            //     curCity,
            // });
        } catch (error) {
            console.log(error);
            const curCity = {
                citycode: 110000,
                cityname: '北京',
                citypinyin: 'beijing',
            };

            this.setState({
                curCity,
            });
        }
    }

    componentWillUnmount() {
        this.unHandleClick();
    }

    // 全站渠道标记传递，文章页的任意跳转
    handleClick = e => {
        const tag = this.getATag(e.target);

        if (!tag) return;

        let localHref =
            (tag.attributes['sign-trs-href'] ? tag.attributes['sign-trs-href'].value : '') ||
            (tag.attributes['href'] ? tag.attributes['href'].value : '');

        if (
            localHref &&
            localHref !== 'undefined' &&
            localHref !== '' &&
            localHref !== '#' &&
            localHref.indexOf('javascript:') === -1
        ) {
            localHref = localHref.trim();
            const localSearch = location.search;
            const localHash = location.hash;

            tag.setAttribute('sign-trs-href', localHref);

            let newUrl = '';
            let inWhitelist = false;
            const whitelist = ['//dol.deliver.ifeng.com/'];

            for (let i = 0, len = whitelist.length; i < len; i++) {
                if (localHref.indexOf(whitelist[i]) > -1) {
                    inWhitelist = true;
                }
            }
            // 传递下划线开头的统计
            const curSrc = this.getParam(localSearch, localHash);

            if ((localSearch || localHash) && curSrc && !inWhitelist) {
                if (localHref.indexOf('?') > -1) {
                    newUrl = `${localHref}&${curSrc}`;
                } else {
                    newUrl = `${localHref}?${curSrc}`;
                }

                tag.setAttribute('href', newUrl);
            }
        }
    };

    getATag = tag => {
        if (!tag) {
            return null;
        }

        if (tag.tagName !== 'A') {
            return this.getATag(tag.parentElement);
        } else {
            return tag;
        }
    };

    getSign = (localSearch, ret) => {
        const localSearchArr = localSearch.split('#');

        for (let i = 0; i < localSearchArr.length; i++) {
            const localParam = localSearchArr[i];

            if (localParam.indexOf('_') === -1) continue;

            const localParamArr = localParam.split('?');

            for (let j = 0; j < localParamArr.length; j++) {
                const localParam2 = localParamArr[j];

                if (localParam2.indexOf('_') === -1) continue;

                const localParam2Arr = localParam2.split('&');

                for (let m = 0; m < localParam2Arr.length; m++) {
                    const localParam3 = localParam2Arr[m];

                    if (localParam3.indexOf('_') === -1) continue;

                    const localParam3Arr = localParam3.split('|');

                    for (let k = 0; k < localParam3Arr.length; k++) {
                        const localParam4 = localParam3Arr[k];

                        if (localParam4.indexOf('_') !== 0) continue;

                        if (ret === '') {
                            ret += localParam4;
                        } else {
                            ret = `${ret}&${localParam4}`;
                        }
                    }
                }
            }
        }

        return ret;
    };

    getParam = (localSearch, localHash) => {
        let ret = '';

        if (localSearch.indexOf('_zbs') > -1) {
            ret = this.getSign(localSearch, ret);
        }
        if (localHash.indexOf('_zbs') > -1) {
            ret = this.getSign(localHash, ret);
        }

        return ret;
    };

    scrollToAnchor(id) {
        document.documentElement.scrollTop = document.getElementById(`module${id + 1}`).offsetTop;
        // document.documentElement.scrollTop = 3000;
    }

    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        const headerData = {
            nav: content.nav,
            search: content.search,
            topAd: content.topAd,
            logo: content.logo,
            channelAd: content.channelAd,
            curCity: this.state.curCity,
            citysData: {
                cityData: this.state.cityData,
                cityLetter: this.state.cityLetter,
            },
        };

        const contentData = {
            banner01Ad: content.banner01Ad,
            banner03Ad: content.banner03Ad,
            banner04Ad: content.banner04Ad,
            carImageAd: content.carImageAd,

            txtInfo: {
                txtNewsInfo: content.txtNewsInfo,
                txtEditInfo: content.txtEditInfo,
            },
            txtInfo2: {
                txtNewsInfo2: content.txtNewsInfo2,
                txtEditInfo: content.txtEditInfo,
            },
            curCity: this.state.curCity,
            citysData: {
                cityData: this.state.cityData,
                cityLetter: this.state.cityLetter,
            },
            hotCarAd: {
                hotCar01Ad: content.hotCar01Ad,
                hotCar02Ad: content.hotCar02Ad,
                hotCarTxt01Ad: content.hotCarTxt01Ad,
                hotCarTxt02Ad: content.hotCarTxt02Ad,
            },
            hotArticleRank: content.hotArticleRank,
            viewPoints: {
                personView: content.personView,
                dujiaView: content.dujiaView,
            },
            swiperData: {
                swiperFirst: content.swiperFirst,
                swiperSecond: content.swiperSecond,
                swiperThird: content.swiperThird,
                ad: {
                    swiperCar01Ad: content.swiperCar01Ad,
                    swiperCar02Ad: content.swiperCar02Ad,
                    swiperCar03Ad: content.swiperCar03Ad,
                },
            },
            txtInfoAd: {
                txtInfo03Ad: content.txtInfo03Ad,
                txtInfo04Ad: content.txtInfo04Ad,
            },
            txtInfoAd2: {
                txtInfo05Ad: content.txtInfo05Ad,
                txtInfo06Ad: content.txtInfo06Ad,
                txtInfo07Ad: content.txtInfo07Ad,
                txtInfo08Ad: content.txtInfo08Ad,
            },
            swiperContent: [
                [{ swiperFirst: content.swiperFirst }],
                [{ swiperFirst: content.swiperFirst }],
                [{ swiperFirst: content.swiperFirst }],
            ],
            info: {
                infoTitle: content.infoTitle,
                infoAd: content.infoAd,
                infoAd2: content.infoAd2,
                infoAd3: content.infoAd3,
                infoYg: content.infoYg,
                informationtext01: content.informationtext01,
                informationtext02: content.informationtext02,
                informationtext03: content.informationtext03,
                informationtext04: content.informationtext04,
                hardAd: content.hardAd,
                infoStream: [content.newsstream0.slice(0, 17), content.newsstream1.slice(0, 12)],
                newsstreamTxtEdit: content.newsstreamTxtEdit,
                newsstreamSecondEdit: content.newsstreamSecondEdit,
                newsstreamFourthEdit: content.newsstreamFourthEdit,
                newsstreamSixthEdit: content.newsstreamSixthEdit,
                banner03Ad: content.banner03Ad,
                banner04Ad: content.banner04Ad,
                banner05Ad: content.banner05Ad,
                banner06Ad: content.banner06Ad,
                carImageAd: content.carImageAd,
                trigger01Ad: content.trigger01Ad,
                trigger02Ad: content.trigger02Ad,
            },
            rightContent: {
                industryNews: content.industryNews,
                rectangle01Ad: content.rectangle01Ad,
                rectangle02Ad: content.rectangle02Ad,
                newcarIntroAd01: content.newcarIntroAd01,
                newcarIntroAd04: content.newcarIntroAd04,
            },
            autoVideoList: content.autoVideoList,
            globalAutoTalk: content.globalAutoTalk,
            hotSubject: content.hotSubject,
        };

        const footerData = {
            bottomAd: content.bottomAd,
            footer: content.footer,
        };
        const cooperation = content.cooperation;
        const qrCode = content.qrCode;
        const bottomAffixData = {
            floatAd1: content.floatAd1,
            floatAd2: content.floatAd2,
            floatAd3: content.floatAd3,
            floatAd4: content.floatAd4,
            floatAd5: content.floatAd5,
        };

        return (
            <div className={styles.ip_col} id="module">
                <Header content={headerData} />
                <Chip id="260815" type="struct" title="汽车导航" groupName="导航栏" content={content.navigation}>
                    <TopNav />
                </Chip>
                <Content content={contentData} />
                <NoSSR>
                    <Chip
                        id="40117"
                        type="static"
                        title="底部合作链接"
                        groupName="底部合作链接"
                        position="relative"
                        content={cooperation}>
                        <Cooperation />
                    </Chip>
                </NoSSR>
                <BottomFooter content={footerData} />
                <div className={styles.bottom} />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
