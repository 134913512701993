import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../utils/rel';
import EnqueryAlert from '../../components/enqueryAlert';
import { getBrandandSerialList, getTypeData } from '../../../../services/api';

class Enquery extends React.PureComponent {
    static propTypes = {
        curCity: PropTypes.object,
    };
    state = {
        brandData: [],
        brand: -1,
        serial: -1,
        type: -1,
        seriesData: [],
        typeData: [],
        citypinyin: '',
        isEnqueryAlertShow: false,
        isEnqueryShow: true,
        carSeriesData: {
            brand: -1,
            serial: -1,
            type: -1,
        },
    };

    async componentDidMount() {
        try {
            const brandData = await getBrandandSerialList();

            this.setState({
                brandData,
            });
        } catch (e) {
            console.error(e);
        }
    }

    brandChange = e => {
        this.setState({
            brand: e.currentTarget.value,
            seriesData: [],
            typeData: [],
            serial: -1,
            type: -1,
        });

        const brandData = this.state.brandData;

        for (let el in brandData) {
            if (brandData[el].i.toString() === e.currentTarget.value.toString()) {
                this.setState({
                    seriesData: brandData[el].c,
                });
            }
        }
    };

    serialChange = e => {
        this.setState({
            typeData: [],
            serial: e.currentTarget.value,
            type: -1,
        });
        const seriesData = this.state.seriesData;

        for (let el in seriesData) {
            const serialLi = seriesData[el].s;

            for (let serialEl in serialLi) {
                if (serialLi[serialEl].i.toString() === e.currentTarget.value.toString()) {
                    this.setState({
                        typeData: serialLi[serialEl].b,
                    });
                }
            }
        }
    };

    typeChange = e => {
        this.setState({
            type: e.currentTarget.value,
        });
    };

    enqueryClick = () => {
        this.setState({
            isEnqueryAlertShow: true,
        });
        let carSeriesData = { brand: this.state.brand, serial: this.state.serial, type: this.state.type };

        this.setState({
            carSeriesData,
        });

        // this.refs.myTest.setInfo(true);
    };
    enqueryClose = () => {
        this.setState({
            isEnqueryShow: false,
        });
    };
    getChildData = res => {
        this.setState({
            isEnqueryAlertShow: res,
        });
    };

    render() {
        const { curCity } = this.props;
        const {
            brandData,
            seriesData,
            typeData,
            brand,
            serial,
            type,
            isEnqueryAlertShow,
            isEnqueryShow,
            carSeriesData,
        } = this.state;

        return (
            <div>
                {isEnqueryShow ? (
                    <div className={styles.enquiry_bottom_bar}>
                        <div>
                            <div className={styles.ebb_mask} />
                            <div className={styles.enquiry_bar_con}>
                                <i />
                                <div className={styles.enquiry_bar_data}>
                                    <select name="car_brand" id="car_brand" onChange={this.brandChange} value={brand}>
                                        <option value="-1">请选择品牌</option>
                                        {brandData.map((item, index) => (
                                            <option key={index} value={item.i}>
                                                {item.n}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        name="car_serial"
                                        id="car_serial"
                                        onChange={this.serialChange}
                                        value={serial}>
                                        <option value="-1">请选择车系</option>
                                        {seriesData.map((item, index) => (
                                            <optgroup key={index} label={item.n}>
                                                {item.n}
                                                {seriesData[index].s.map((serialItem, serialIndex) => (
                                                    <option key={serialIndex} value={serialItem.i}>
                                                        {serialItem.n}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                    <select name="car_type" id="car_type" onChange={this.typeChange} value={type}>
                                        <option value="-1">请选择车型</option>
                                        {typeData.map((item, index) => (
                                            <option key={index} value={item.i}>
                                                {item.n}
                                            </option>
                                        ))}
                                    </select>
                                    <div className={styles.btn} onClick={this.enqueryClick}>
                                        获取最低价
                                    </div>
                                    <div className={styles.close} title="关闭" onClick={this.enqueryClose}>
                                        <span />
                                    </div>
                                </div>
                            </div>
                            {isEnqueryAlertShow ? (
                                <EnqueryAlert
                                    ref="myTest"
                                    news={this}
                                    carSeriesData={carSeriesData}
                                    curCity={curCity}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default errorBoundary(Enquery);
