import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Slides from '@ifeng-fe/ui_pc_slides';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../../utils/rel';
import { getMcmsSwiper } from '../../../../../services/api';
import Chip from 'Chip';
import { handleAd } from '../../../../../utils/handleAd';
import { formatImage } from '@ifeng-fe/public_method';

class BannerPic extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        swiperData: PropTypes.object,
        // curCity: PropTypes.object,
    };
    state = {
        mcmsSwiperData: PropTypes.string,
    };
    ref = React.createRef();
    async componentDidMount() {
        try {
            // const mcmsSwiperData = await getMcmsSwiper(
            //     this.props.curCity && this.props.curCity.citypinyin ? this.props.curCity.citypinyin : 'beijing',
            // );

            // this.setState({
            //     mcmsSwiperData,
            // });
            const callbackFn = await handleAd(this.props.swiperData.ad.swiperCar01Ad);
            const callbackFn2 = await handleAd(this.props.swiperData.ad.swiperCar02Ad);
            const callbackFn3 = await handleAd(this.props.swiperData.ad.swiperCar03Ad);

            callbackFn(this.ref.current, this.props.swiperData.ad.swiperCar01Ad.data);
            callbackFn2(this.ref.current, this.props.swiperData.ad.swiperCar02Ad.data);
            callbackFn3(this.ref.current, this.props.swiperData.ad.swiperCar03Ad.data);
        } catch (e) {
            console.error(e);
        }
    }

    // 渲染导航

    swiperView(list = []) {}

    sliderTmpl = (item, isClone, index) => {
        const { swiperData } = this.props;
        const swiperFirst = swiperData.swiperFirst.map(item => {
            return {
                ...item,
                thumbnail:
                    (item.thumbnail && formatImage(item.thumbnail, 1024, 680)) ||
                    (item?.thumbnails?.image?.[0]?.url && formatImage(item.thumbnails.image[0].url, 1024, 680)) ||
                    '',
            };
        });
        const swiperSecond = swiperData.swiperSecond.map(item => {
            return {
                ...item,
                thumbnail:
                    (item.thumbnail && formatImage(item.thumbnail, 1024, 680)) ||
                    (item?.thumbnails?.image?.[0]?.url && formatImage(item.thumbnails.image[0].url, 1024, 680)) ||
                    '',
            };
        });
        const swiperThird = swiperData.swiperThird.map(item => {
            return {
                ...item,
                thumbnail:
                    (item.thumbnail && formatImage(item.thumbnail, 1024, 680)) ||
                    (item?.thumbnails?.image?.[0]?.url && formatImage(item.thumbnails.image[0].url, 1024, 680)) ||
                    '',
            };
        });
        
        if (index === 1) {
            return (
                <div>
                    <Chip
                        id="288384"
                        type="recommend"
                        title="首页焦点图第一帧"
                        groupName="焦点图"
                        content={swiperData.swiperFirst}>
                        <div className={styles.content} ref="swiperFirst">
                            <div className={styles.img_l}>
                                <div className={styles.img}>
                                    <a href={swiperFirst[0]?.url} target="_blank">
                                        <img src={swiperFirst[0]?.thumbnail} alt={swiperFirst[0]?.title} />
                                        <div className={styles.img_t}>{swiperFirst[0]?.title}</div>
                                    </a>

                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.t_b}>
                                    <h2>
                                        <a href={swiperFirst[1]?.url} target="_blank">
                                            {swiperFirst[1]?.title || ''}
                                        </a>
                                    </h2>
                                    <div className={styles.t_b_stitle}>
                                        <span>
                                            <a href={swiperFirst[2]?.url} target="_blank">
                                                [{swiperFirst[2]?.title || ''}]
                                            </a>
                                        </span>
                                        &nbsp;
                                        <span>
                                            <a href={swiperFirst[3]?.url} target="_blank">
                                                [{swiperFirst[3]?.title || ''}]
                                            </a>
                                        </span>
                                    </div>
                                    <i />
                                </div>
                            </div>
                            <div className={styles.img_m}>
                                <div className={styles.img}>
                                    <a href={swiperFirst[4]?.url} target="_blank">
                                        <img src={swiperFirst[4]?.thumbnail} alt={swiperFirst[4]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperFirst[4]?.title || ''}</div>

                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.img}>
                                    <a href={swiperFirst[5]?.url} target="_blank">
                                        <img src={swiperFirst[5]?.thumbnail} alt={swiperFirst[5]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperFirst[5]?.title || ''}</div>
                                    <div className={styles.t_m} />
                                </div>
                            </div>
                            <div className={styles.img_r}>
                                <div className={styles.t_b}>
                                    <h2>
                                        <a href={swiperFirst[6]?.url} target="_blank">
                                            {swiperFirst[6]?.title || ''}
                                        </a>
                                    </h2>
                                    <a href={swiperFirst[7]?.url} target="_blank" className={styles.text_a_font}>
                                        {swiperFirst[7]?.title || ''}
                                    </a>
                                    <span />
                                    <i />
                                </div>

                                <div className={styles.img} id="ad_slide01_con">
                                    <a href={swiperFirst[8]?.url} target="_blank">
                                        <img src={swiperFirst[8]?.thumbnail} alt={swiperFirst[8]?.title || ''} />
                                        <div className={styles.img_t}>{swiperFirst[8]?.title || ''}</div>
                                    </a>
                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.img}>
                                    <a href={swiperFirst[9]?.url} target="_blank">
                                        <img src={swiperFirst[9]?.thumbnail} alt={swiperFirst[9]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperFirst[9]?.title || ''} </div>
                                    <div className={styles.t_m} />
                                </div>
                            </div>
                        </div>
                    </Chip>
                </div>
            );
        } else if (index === 2) {
            return (
                <div>
                    <Chip
                        id="288385"
                        type="recommend"
                        title="首页焦点图第二帧"
                        groupName="焦点图"
                        content={swiperData.swiperSecond}
                        ref="swiperSecond">
                        <div className={styles.content}>
                            <div className={styles.img_l}>
                                <div className={styles.img}>
                                    <a href={swiperSecond[0]?.url} target="_blank">
                                        <img src={swiperSecond[0]?.thumbnail} alt={swiperSecond[0]?.title} />
                                        <div className={styles.img_t}>{swiperSecond[0]?.title}</div>
                                    </a>

                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.t_b}>
                                    <h2>
                                        <a href={swiperSecond[1]?.url} target="_blank">
                                            {swiperSecond[1]?.title || ''}
                                        </a>
                                    </h2>
                                    <div className={styles.t_b_stitle}>
                                        <span>
                                            <a href={swiperSecond[2]?.url} target="_blank">
                                                [{swiperSecond[2]?.title || ''}]
                                            </a>
                                        </span>
                                        &nbsp;
                                        <span>
                                            <a href={swiperSecond[3]?.url} target="_blank">
                                                [{swiperSecond[3]?.title || ''}]
                                            </a>
                                        </span>
                                    </div>
                                    <i />
                                </div>
                            </div>
                            <div className={styles.img_m}>
                                <div className={styles.img}>
                                    <a href={swiperSecond[4]?.url} target="_blank">
                                        <img src={swiperSecond[4]?.thumbnail} alt={swiperSecond[4]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperSecond[4]?.title || ''}</div>

                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.img}>
                                    <a href={swiperSecond[5]?.url} target="_blank">
                                        <img src={swiperSecond[5]?.thumbnail} alt={swiperSecond[5]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperSecond[5]?.title || ''}</div>
                                    <div className={styles.t_m} />
                                </div>
                            </div>
                            <div className={styles.img_r}>
                                <div className={styles.t_b}>
                                    <h2>
                                        <a href={swiperSecond[6]?.url} target="_blank">
                                            {swiperSecond[6]?.title || ''}
                                        </a>
                                    </h2>
                                    <a href={swiperSecond[7]?.url} target="_blank" className={styles.text_a_font}>
                                        {swiperSecond[7]?.title || ''}
                                    </a>
                                    <span />
                                    <i />
                                </div>

                                <div className={styles.img} id="ad_slide01_con">
                                    <a href={swiperSecond[8]?.url} target="_blank">
                                        <img src={swiperSecond[8]?.thumbnail} alt={swiperSecond[8]?.title || ''} />
                                        <div className={styles.img_t}>{swiperSecond[8]?.title || ''}</div>
                                    </a>
                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.img}>
                                    <a href={swiperSecond[9]?.url} target="_blank">
                                        <img src={swiperSecond[9]?.thumbnail} alt={swiperSecond[9]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperSecond[9]?.title || ''} </div>
                                    <div className={styles.t_m} />
                                </div>
                            </div>
                        </div>
                    </Chip>
                </div>
            );
        } else if (index === 3) {
            return (
                <div>
                    <Chip
                        id="288386"
                        type="recommend"
                        title="首页焦点图第三帧"
                        groupName="焦点图"
                        content={swiperData.swiperThird}>
                        <div className={styles.content} ref="swiperThird">
                            <div className={styles.img_l}>
                                <div className={styles.img}>
                                    <a href={swiperThird[0]?.url} target="_blank">
                                        <img src={swiperThird[0]?.thumbnail} alt={swiperThird[0]?.title} />
                                        <div className={styles.img_t}>{swiperThird[0]?.title}</div>
                                    </a>

                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.t_b}>
                                    <h2>
                                        <a href={swiperThird[1]?.url} target="_blank">
                                            {swiperThird[1]?.title || ''}
                                        </a>
                                    </h2>
                                    <div className={styles.t_b_stitle}>
                                        <span>
                                            <a href={swiperThird[2]?.url} target="_blank">
                                                [{swiperThird[2]?.title || ''}]
                                            </a>
                                        </span>
                                        &nbsp;
                                        <span>
                                            <a href={swiperThird[3]?.url} target="_blank">
                                                [{swiperThird[3]?.title || ''}]
                                            </a>
                                        </span>
                                    </div>
                                    <i />
                                </div>
                            </div>
                            <div className={styles.img_m}>
                                <div className={styles.img}>
                                    <a href={swiperThird[4]?.url} target="_blank">
                                        <img src={swiperThird[4]?.thumbnail} alt={swiperThird[4]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperThird[4]?.title || ''}</div>

                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.img}>
                                    <a href={swiperThird[5]?.url} target="_blank">
                                        <img src={swiperThird[5]?.thumbnail} alt={swiperThird[5]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperThird[5]?.title || ''}</div>
                                    <div className={styles.t_m} />
                                </div>
                            </div>
                            <div className={styles.img_r}>
                                <div className={styles.t_b}>
                                    <h2>
                                        <a href={swiperThird[6]?.url} target="_blank">
                                            {swiperThird[6]?.title || ''}
                                        </a>
                                    </h2>
                                    <a href={swiperThird[7]?.url} target="_blank" className={styles.text_a_font}>
                                        {swiperThird[7]?.title || ''}
                                    </a>
                                    <span />
                                    <i />
                                </div>

                                <div className={styles.img} id="ad_slide01_con">
                                    <a href={swiperThird[8]?.url} target="_blank">
                                        <img src={swiperThird[8]?.thumbnail} alt={swiperThird[8]?.title || ''} />
                                        <div className={styles.img_t}>{swiperThird[8]?.title || ''}</div>
                                    </a>
                                    <div className={styles.t_m} />
                                </div>

                                <div className={styles.img}>
                                    <a href={swiperThird[9]?.url} target="_blank">
                                        <img src={swiperThird[9]?.thumbnail} alt={swiperThird[9]?.title || ''} />
                                    </a>
                                    <div className={styles.img_t}>{swiperThird[9]?.title || ''} </div>
                                    <div className={styles.t_m} />
                                </div>
                            </div>
                        </div>
                    </Chip>
                </div>
            );
        } else {
            return (
                // <div >c cc</div>
                <div style={{ position: 'relative' }}>
                    <div dangerouslySetInnerHTML={{ __html: this.state.mcmsSwiperData }} />
                </div>
            );
        }
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const config = {
            arrows: false,
            autoplay: false,
            direction: 'forward',
            axis: 'horizonta',
            sliderTmpl: this.sliderTmpl,
            dotStyle: styles.dot_style,
            dotCurrentStyle: styles.current,
        };

        return (
            <div className={styles.bannerPic} ref={this.ref}>
                <Slides content={content} config={config} />
            </div>
        );
    }
}

export default errorBoundary(BannerPic);
