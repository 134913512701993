import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../../utils/rel';
import { getChenZhan } from '../../../../../services/api';
import { formatImage } from '@ifeng-fe/public_method';
import Title from '../../../components/title';
import SubTitle from '../../../components/subtitle';
import PropTypes from 'prop-types';

class ViewPoints extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        hotSubject: PropTypes.array,
    };

    render() {
        const { personView } = this.props.content;
        const { hotSubject } = this.props;

        return (
            <div className={styles.view_points}>
                <div className={styles.person_view}>
                    <div className={styles.person_title}>
                        <SubTitle content={{ title: '人物访谈' }} />
                    </div>
                    <ul>
                        {personView.map((item, index) => (
                            <li key={index}>
                                <a href={item.url} rel={rel} target="_blank">
                                    <img src={item.thumbnails} alt={item.title} />
                                    <p>{item.title}</p>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.view_right}>
                    <div className={styles.chenzhan_view}>
                        <div className={styles.chenzhan_title}>
                            <SubTitle content={{ title: '热门专题' }} />
                        </div>
                        <ul>
                            {hotSubject.map((item, index) => (
                                <li key={index}>
                                    <a href={item.url} rel={rel} target="_blank">
                                        <img src={formatImage(item.image, 170, 114)} alt={item.name} />
                                        <p>{item.name}</p>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(ViewPoints);
