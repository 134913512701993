import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NewsStream from './../newsStream';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { getLocalCarArticle } from '../../../../../../services/api';

const cutImage = {
    oneImage: {
        width: 198,
        height: 112,
    },
    threeImage: {
        width: 198,
        height: 112,
    },
};

const classNames = function classNames(...args) {
    return args.join(' ');
};

class TabsNews extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
        curCity: PropTypes.object,
        newsstreamTxtEdit: PropTypes.array,
        newsstreamSecondEdit: PropTypes.array,
        newsstreamFourthEdit: PropTypes.array,
        newsstreamSixthEdit: PropTypes.array,
    };

    moreUrl = ['//auto.ifeng.com/shanklist/10-', '', '//auto.ifeng.com/beijing/'];

    constructor(...argu) {
        super(...argu);

        const { content, ad, curCity } = this.props;
        const { moreUrl } = this;

        this.state = {
            contentData: this.props.content,
            index: 0,
            element: [
                <NewsStream
                    key={0}
                    typeId={0}
                    content={this.props.content[0]}
                    isCount={false}
                    moreHref={moreUrl[0]}
                    curCity={curCity}
                    ad={this.props.ad}
                    newsstreamTxtEdit={this.props.newsstreamTxtEdit}
                    newsstreamSecondEdit={this.props.newsstreamSecondEdit}
                    newsstreamFourthEdit={this.props.newsstreamFourthEdit}
                    newsstreamSixthEdit={this.props.newsstreamSixthEdit}
                />,
                null,
                null,
                null,
            ],
            tabs: ['最新', '自媒体'],
        };
    }

    async componentDidMount() {
        // const localCarArticleData = await getLocalCarArticle(
        //     this.props.curCity && this.props.curCity.citypinyin ? this.props.curCity.citypinyin : 'beijing',
        // );

        // const _localCarArticleData = await this.handleLocalCarArticle(localCarArticleData);

        // this.props.content.push(_localCarArticleData);

        // this.setState({
        //     contentData: this.props.content,
        // });
    }

    async handleLocalCarArticle(arr) {
        let newslist = arr.map(value => {
            return {
                id: value.id,
                title: value.title,
                url: value.url,
                thumbnails: { image: [{ url: typeof value.thumb === 'undefined' ? value.imgurl : value.thumb }] },
                newsTime: value.inputtime,
                source: value.catname,
                type: 'article',
                searchPath: '',
            };
        });

        return newslist;
    }

    // 样式
    styles(tabIndex) {
        const index = this.state ? this.state.index : 0;

        return index === tabIndex ? styles.itis : undefined;
    }
    block(tabIndex) {
        const index = this.state ? this.state.index : 0;

        return { display: index === tabIndex ? 'block' : 'none', width: '698px' };
    }
    // 改变选项卡
    handleChangeIndex(index, event) {
        const { element } = this.state;
        const { content, ad } = this.props;
        const newState = { index };

        if (!element[index]) {
            element[index] = (
                <NewsStream
                    key={index}
                    typeId={index}
                    content={this.state.contentData[index]}
                    isCount={false}
                    moreHref={this.moreUrl[index]}
                />
            );

            newState.element = element;
        }

        this.setState(newState);
    }
    // 渲染选择器
    tabsView() {
        return this.state.tabs.map((item, index) => (
            <li key={index} className={this.styles(index)} onMouseOver={this.handleChangeIndex.bind(this, index)}>
                {item}
            </li>
        ));
    }
    // 渲染数据
    listView() {
        return this.state.element.map((item, index) => (
            <div key={index} style={this.block(index)}>
                {item}
            </div>
        ));
    }
    render() {
        return (
            <div className={styles.tabsNews}>
                <div className="tabs_title">
                    <div className={styles.cut} />
                    <ul className={classNames(styles.tabs, 'clearfix')}>{this.tabsView()}</ul>
                </div>

                {this.listView()}
            </div>
        );
    }
}

export default errorBoundary(TabsNews);
