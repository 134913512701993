import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Title from '../../../components/title';
import { formatImage } from '@ifeng-fe/public_method';

class AutoVideo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        title: PropTypes.string,
    };
    constructor(props) {
        super(props);
        this.state = {
            autoVideoData: this.props.content,
        };
    }

    showVideoAutoView() {
        return (
            <ul className={styles.auto_ul}>
                <li className={styles.auto_first}>
                    <a target="_blank" href={this.state.autoVideoData[0].url}>
                        <img src={formatImage(this.state.autoVideoData[0].posterUrl, 575, 323)} />
                        <p className={styles.auto_title}>{this.state.autoVideoData[0].title}</p>
                        <em className={styles.auto_first_icon_video} />
                    </a>
                </li>
                <li className={styles.auto_ul_li}>
                    <a target="_blank" href={this.state.autoVideoData[1].url}>
                        <img src={formatImage(this.state.autoVideoData[1].posterUrl, 238, 134)} />
                        <p className={styles.auto_title}>{this.state.autoVideoData[1].title}</p>
                        <em className={styles.auto_icon_video} />
                    </a>
                </li>
                <li className={styles.auto_ul_li}>
                    <a target="_blank" href={this.state.autoVideoData[2].url}>
                        <img src={formatImage(this.state.autoVideoData[2].posterUrl, 238, 134)} />
                        <p className={styles.auto_title}>{this.state.autoVideoData[2].title}</p>
                        <em className={styles.auto_icon_video} />
                    </a>
                </li>
                <li className={styles.auto_ul_li}>
                    <a target="_blank" href={this.state.autoVideoData[3].url}>
                        <img src={formatImage(this.state.autoVideoData[3].posterUrl, 238, 134)} />
                        <p className={styles.auto_title}>{this.state.autoVideoData[3].title}</p>
                        <em className={styles.auto_icon_video} />
                    </a>
                </li>
                <li className={styles.auto_ul_li}>
                    <a target="_blank" href={this.state.autoVideoData[4].url}>
                        <img src={this.state.autoVideoData[4].posterUrl} />
                        <p className={styles.auto_title}>{this.state.autoVideoData[4].title}</p>
                        <em className={styles.auto_icon_video} />
                    </a>
                </li>
            </ul>
        );
    }

    render() {
        return (
            <div className={styles.auto_video}>
                {this.state.autoVideoData && this.state.autoVideoData.length > 0 ? (
                    <div>
                        <Title content={this.props.title} />
                        {this.showVideoAutoView()}
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default AutoVideo;
