import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../utils/rel';
import { getCityInfo } from '../../../../services/api';

class Citys extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggleOnIndex: 0,
            citycode: this.props.curCity && this.props.curCity.citycode ? this.props.curCity.citycode : '110000',
            cityName: this.props.curCity && this.props.curCity.cityname ? this.props.curCity.cityname : '北京',
            cityData: this.props.citysData && this.props.citysData.cityData ? this.props.citysData.cityData : [],
            cityLetter: this.props.citysData && this.props.citysData.cityLetter ? this.props.citysData.cityLetter : [],
            cityShow: false,
        };
    }
    static propTypes = {
        content: PropTypes.array,
        curCity: PropTypes.object,
        citysData: PropTypes.object,
    };

    cityHandlerLeaver = e => {
        e.stopPropagation();
        this.setState(() => ({
            cityShow: false,
        }));
    };

    cityHandlerEnter = e => {
        e.stopPropagation();
        this.setState(() => ({
            cityShow: true,
        }));
    };

    scrollToAnchor(id) {
        document.getElementById('city').scrollTop =
            document.getElementById(id).offsetTop +
            document.getElementById(id).offsetHeight -
            65 -
            document.getElementById('city').offsetHeight / 2;
    }

    render() {
        const { content, curCity } = this.props;
        const { toggleOnIndex, cityData, cityLetter, cityShow, cityName } = this.state;

        return (
            <div
                className={styles.arl_pro_city}
                onClick={this.cityShow}
                onMouseEnter={this.cityHandlerEnter}
                onMouseLeave={this.cityHandlerLeaver}>
                {/* <div className={styles.on}>
                    <label>当前城市：</label>
                    <b id="current-city">{cityName}</b>
                </div> */}
                <div className={styles.nav_link}>
                    <div className={styles.btn}>
                        <span>
                            <a href={`//auto.ifeng.com/${curCity.citypinyin}/`} target="_blank" rel={rel}>
                                {cityName}
                            </a>
                        </span>
                        <i />
                    </div>
                    <div className={cityShow ? styles.cx_city_n : styles.cx_city_hide}>
                        {/* <a
                            className={styles.close}
                            onClick={() => {
                                this.cityClose();
                            }}>
                            关闭
                        </a> */}
                        {/* <i className={styles.arrow} /> */}
                        <div className={styles.cx_city_br}>
                            <div className={styles.cx_city_le}>
                                <ul>
                                    {cityLetter &&
                                        cityLetter.length > 0 &&
                                        cityLetter.map((item, index) => (
                                            <li key={index}>
                                                <a href="javascript:void(0);" onClick={() => this.scrollToAnchor(item)}>
                                                    {item}
                                                </a>
                                            </li>
                                        ))}
                                    <li>
                                        <a
                                            className={styles.a_all_city}
                                            target="_blank"
                                            href="//auto.ifeng.com/citys/"
                                            rel={rel}>
                                            全部城市
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.cx_city_nlst} id="city">
                                {cityData &&
                                    cityData.length > 0 &&
                                    cityData.map((item, index) => (
                                        <dl key={index} className={index % 2 === 0 ? styles.cbg : ''}>
                                            <dt id={item[0].toUpperCase()}>{item[0].toUpperCase()}</dt>
                                            {item.slice(1, item.length).map((liitem, liindex) => (
                                                <dd key={liindex}>
                                                    <a
                                                        href={`https://auto.ifeng.com/${liitem
                                                            .split(',')[1]
                                                            .replace(/(^\s*)|(\s*$)/g, '')}/`}
                                                        val={liitem.split(',')[1].replace(/(^\s*)|(\s*$)/g, '')}
                                                        data={liitem.split(',')[2].replace(/(^\s*)|(\s*$)/g, '')}
                                                        target="_blank"
                                                        rel={rel}>
                                                        {liitem.split(',')[0]}
                                                    </a>
                                                </dd>
                                            ))}
                                        </dl>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Citys);
