import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../../utils/rel';
import PropTypes from 'prop-types';
import { handleAd } from '../../../../../utils/handleAd';

class TxtInfoFlow extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        ad2: PropTypes.object,
    };
    state = {
        typeAllData: {},
    };

    async componentDidMount() {
        const callbackFn5 = await handleAd(this.props.ad2.txtInfo05Ad);

        callbackFn5(this.refs.txtInfo2, this.props.ad2.txtInfo05Ad.data);
        const callbackFn6 = await handleAd(this.props.ad2.txtInfo06Ad);

        callbackFn6(this.refs.txtInfo2, this.props.ad2.txtInfo06Ad.data);
        const callbackFn7 = await handleAd(this.props.ad2.txtInfo07Ad);

        callbackFn7(this.refs.txtInfo2, this.props.ad2.txtInfo07Ad.data);

        const callbackFn8 = await handleAd(this.props.ad2.txtInfo08Ad);

        callbackFn8(this.refs.txtInfo2, this.props.ad2.txtInfo08Ad.data);
    }

    render() {
        const { txtNewsInfo2, txtEditInfo } = this.props.content;
        const { typeAllData } = this.state;

        if (txtEditInfo && txtEditInfo.length > 0) {
            if (txtEditInfo.length < 2) {
                txtNewsInfo2.splice(0, 1, txtEditInfo[0]);
            } else {
                txtNewsInfo2.splice(0, 2, txtEditInfo[0], txtEditInfo[1]);
            }
        }

        return (
            <div className={styles.img_ad_b}>
                <ul ref="txtInfo2">
                    {txtNewsInfo2.map((item, index) => (
                        <li key={index}>
                            <a href={item.url} target="_blank" rel={rel}>
                                {item.title2 ? item.title2 : item.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(TxtInfoFlow);
