import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import rel from '../../../../utils/rel';

class SubTitle extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { title, middleData, rightData } = this.props.content;

        return (
            <div className={styles.title}>
                <div className={styles.title_l}>
                    <div className={styles.cut} />
                    <span className={styles.name}>{title}</span>
                    {middleData && middleData.length > 0
                        ? middleData.map((item, index) => (
                              <span className={styles.middle} key={index}>
                                  <a href={item.url ? item.url : ' javascript:void(0);'} target="_blank" rel={rel}>
                                      {item.name}
                                  </a>
                              </span>
                          ))
                        : ''}
                </div>
                <div className={styles.title_r}>
                    {rightData && rightData.length > 0
                        ? rightData.map((item, index) => (
                              <span key={index}>
                                  <a href={item.url ? item.url : ' javascript:void(0);'} target="_blank" rel={rel}>
                                      {item.name}
                                  </a>
                              </span>
                          ))
                        : ''}
                </div>
            </div>
        );
    }
}

export default errorBoundary(SubTitle);
