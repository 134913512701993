import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { Event } from '@ifeng-fe/ui_base';
import { rel } from '../../../../utils/rel';
import errorBoundary from '@ifeng-fe/errorBoundary';
import {
    getBrandandSerialList,
    getFastXunjia,
    getXunjiaNews,
    getMessageHander,
    getPriceInterval,
    getXunjiaCitys,
} from '../../../../services/api';
import provinceData from './provinceData.json';

class EnqueryAlert extends React.PureComponent {
    static propTypes = {
        // content: PropTypes.object,
        curCity: PropTypes.object,
        news: PropTypes.object,
        carSeriesData: PropTypes.object,
    };
    constructor(props) {
        super(props);

        this.state = {
            brandData: [],
            brand: -1,
            serial: -1,
            type: -1,
            brandName: '',
            serialName: '',
            typeName: '',
            seriesData: [],
            typeData: [],
            province: -1,
            city: -1,
            cityName: '',
            cityData: [],
            user: '',
            gender: '0',
            tel: '',
            serialRecommen: [],
            xunjiaNews: [],
            getMessageHander: [],
            isEnqueryFormShow: true,
            isEnquerySucess: false,
            failTop: 0,
            failMarginTop: 0,
            sucessTop: 0,
            sucessMarginTop: 0,
            priceInterval: {},
            sendData: {},
            message: '恭喜您！询价成功！',
            isagree: false,
        };
    }
    enquiryFail = React.createRef();
    enquirySucess = React.createRef();

    async componentDidMount() {
        const { curCity, carSeriesData } = this.props;

        // 设置城市默认值
        if (curCity && curCity.citycode && (curCity.citycode !== undefined || curCity.citycode !== '')) {
            for (let province of provinceData) {
                for (let city of province.city) {
                    if (city.code.toString() === curCity.citycode) {
                        this.setState({
                            province: province.code,
                            cityData: province.city,
                            city: city.code,
                            cityName: city.name,
                        });
                    }
                }
            }
        } else {
            this.setState({
                province: 110000,
                city: -1,
                cityData: [],
            });

            for (let province of provinceData) {
                if (province.code.toString() === '110000') {
                    this.setState({
                        cityData: province.city,
                        city: 110000,
                        cityName: '北京',
                    });
                }
            }
        }

        // if()
        try {
            const brandData = await getBrandandSerialList();

            this.setState({
                brandData,
            });
            if (carSeriesData.brand && carSeriesData.brand !== -1) {
                this.setState({
                    brand: carSeriesData.brand,
                });
                this.setState({
                    brand: carSeriesData.brand,
                    seriesData: [],
                    typeData: [],
                    serial: -1,
                    type: -1,
                    // citypinyin: curCity.citypinyin ? curCity.citypinyin : 'beijing',
                });
                const seriesData = [];
                let brandData = this.state.brandData;

                for (let el in brandData) {
                    if (brandData[el].i.toString() === carSeriesData.brand.toString()) {
                        this.setState({
                            brandName: brandData[el].n,
                            seriesData: brandData[el].c,
                            serial: carSeriesData.serial,
                            typeData: [],
                            type: -1,
                        });

                        if (carSeriesData.serial && carSeriesData.serial !== -1) {
                            let seriesData = this.state.seriesData;

                            for (let dealer of seriesData) {
                                //     // seriesData.push(brandData[el].i);
                                for (let serial of dealer.s) {
                                    if (serial.i.toString() === carSeriesData.serial.toString()) {
                                        this.setState({
                                            typeData: serial.b,
                                            type: carSeriesData.type,
                                            serialName: serial.n,
                                        });
                                        if (carSeriesData.type && carSeriesData.type !== -1) {
                                            let typeData = this.state.typeData;

                                            if (typeData.length > 0) {
                                                for (let type of typeData) {
                                                    if (type.i.toString() === carSeriesData.type.toString()) {
                                                        this.setState({
                                                            typeName: type.n,
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // this.brandChange();
            }

            if (curCity) {
                this.setState({
                    province: curCity.citycode,
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    setInfo = val => {
        // 这里的val就是父组件通过调用这个方法，传的参数，在这里val的值为aaa
        // this.setState({
        //     msg: val
        // })
    };

    brandChange = e => {
        this.setState({
            brand: e.currentTarget.value,
            seriesData: [],
            typeData: [],
            serial: -1,
            type: -1,
        });
        const seriesData = [];
        const brandData = this.state.brandData;

        for (let el in brandData) {
            // seriesData.push(brandData[el].i);
            if (brandData[el].i.toString() === e.currentTarget.value.toString()) {
                this.setState({
                    seriesData: brandData[el].c,
                    brandName: brandData[el].n,
                });
            }
        }
    };

    serialChange = e => {
        this.setState({
            typeData: [],
            serial: e.currentTarget.value,
            type: -1,
        });
        let seriesData = this.state.seriesData;

        for (let dealer of seriesData) {
            for (let serial of dealer.s) {
                if (serial.i.toString() === e.currentTarget.value.toString()) {
                    this.setState({
                        typeData: serial.b,
                        serialName: serial.n,
                    });
                }
            }
        }
    };

    typeChange = e => {
        this.setState({
            type: e.currentTarget.value,
        });
        let typeData = this.state.typeData;

        if (typeData.length > 0) {
            for (let type of typeData) {
                if (type.i.toString() === e.currentTarget.value.toString()) {
                    this.setState({
                        typeName: type.n,
                    });
                }
            }
        }
    };
    userChange = e => {
        this.setState({
            user: e.currentTarget.value,
        });
    };
    genderChange = e => {
        this.setState({
            gender: e.currentTarget.value,
        });
    };
    telChange = e => {
        this.setState({
            tel: e.currentTarget.value,
        });
    };
    provinceChange = e => {
        this.setState({
            province: e.currentTarget.value,
            city: -1,
            cityData: [],
        });

        for (let province of provinceData) {
            if (province.code.toString() === e.currentTarget.value.toString()) {
                this.setState({
                    cityData: province.city,
                });
            }
        }
    };
    cityChange = e => {
        this.setState({
            city: e.currentTarget.value,
        });

        let cityData = this.state.cityData;

        if (cityData.length > 0) {
            for (let city of cityData) {
                if (city.code.toString() === e.currentTarget.value.toString()) {
                    this.setState({
                        cityName: city.name,
                    });
                }
            }
        }
    };
    agreeChange = e => {
        let chk = document.querySelector('#is_agree').checked;

        this.setState({
            isagree: chk,
        });
    };
    enq_setCookie = (c_name, value, expiredays) => {
        let exdate = new Date();

        exdate.setDate(exdate.getDate() + expiredays);
        document.cookie = `${c_name} = escape(${value}) (${expiredays} == null ? '' : ';expires=' + ${exdate}.toGMTString()) ;path=/;domain=auto.ifeng.com;`;
    };

    enq_getCookie = c_name => {
        if (document.cookie.length > 0) {
            let c_start = document.cookie.indexOf(`${c_name} =`);

            if (c_start !== -1) {
                c_start = c_start + c_name.length + 1;
                let c_end = document.cookie.indexOf(';', c_start);

                if (c_end === -1) c_end = document.cookie.length;

                return unescape(document.cookie.substring(c_start, c_end));
            }
        }

        return '';
    };
    async getStockPriceByName(serial) {
        const symbol = await getFastXunjia(serial);

        return symbol;
    }

    async getEnquery() {
        if (!this.state.isagree) {
            alert('请阅读并同意个人信息保护政策和价格咨询与活动报名个人信息保护声明');

            return;
        }
        if (!this.state.brand || this.state.brand === -1 || this.state.brand === '-1') {
            alert('品牌不能为空');

            return;
        }

        if (!this.state.serial || this.state.serial === -1 || this.state.serial === '-1') {
            alert('车系不能为空');

            return;
        }

        if (!this.state.user) {
            alert('用户名不能为空');

            return;
        }
        if (this.state.user.length > 10) {
            alert('请输入正确姓名');

            return;
        }
        if (!this.state.tel) {
            alert('手机不能为空');

            return;
        }
        if (!/^1[358]\d{9}$/g.test(this.state.tel)) {
            alert('请输入正确的手机号码');

            return;
        }
        if (Number(this.state.province) < 0) {
            alert('请选择地区');

            return;
        }
        try {
            try {
                const xunjiaNews = await getXunjiaNews(this.state.serial, this.state.city);

                this.setState({
                    xunjiaNews,
                });
            } catch (error) {
                console.log(error);
            }
            try {
                const serialRecommen = await getFastXunjia(this.state.serial);

                this.setState({
                    serialRecommen,
                });
            } catch (error) {
                console.log(error);
            }
            try {
                const priceInterval = await getPriceInterval(this.state.serial, this.state.city);

                this.setState({
                    priceInterval,
                });
            } catch (error) {
                console.log(error);
            }

            let sendData = {
                userName: encodeURI(this.state.user),
                mobile: this.state.tel,
                cityName: encodeURI(this.state.cityName),
                province: this.state.province,
                city: this.state.city,
                brandId: this.state.brand,
                serialId: this.state.serial,
                carId: this.state.type,
                carName: encodeURI(`${this.state.brandName}${this.state.serialName} ${this.state.typeName}`),
                brandName: encodeURI(this.state.brandName),
                serialName: encodeURI(this.state.brandName + this.state.serialName),
                contactType: 1,
                gender: this.state.gender,
            };

            try {
                const messageHandler = await getMessageHander(sendData);

                if (messageHandler.indexOf('成功') > -1) {
                    let name = decodeURI(this.state.user);

                    this.enq_setCookie('remember_phone_name', `${name} , ${this.state.tel}`, 7);

                    this.setState({
                        isEnqueryFormShow: false,
                        isEnquerySucess: true,
                    });

                    this.setState({
                        failTop: '40%',
                        failMarginTop: `-${this.refs.enquiryFail.offsetHeight / 2}px`,
                        sucessTop: '40%',
                        sucessMarginTop: `-${this.refs.enquirySucess.offsetHeight / 2}px`,
                    });
                } else if (messageHandler.indexOf('询价1次') > -1) {
                    this.setState({
                        isEnqueryFormShow: false,
                        isEnquerySucess: false,
                        message: '同车系、车型每天询价一次，请明天再次询价！',
                    });
                    this.setState({
                        failTop: '40%',
                        failMarginTop: `-${this.refs.enquiryFail.offsetHeight / 2}px`,
                        sucessTop: '40%',
                        sucessMarginTop: `-${this.refs.enquirySucess.offsetHeight / 2}px`,
                    });
                } else if (messageHandler.indexOf('询价3次') > -1) {
                    this.setState({
                        isEnqueryFormShow: false,
                        isEnquerySucess: false,
                        message: '此手机号今天已经询价三次，请明天再次询价！',
                    });
                    this.setState({
                        failTop: '40%',
                        failMarginTop: `-${this.refs.enquiryFail.offsetHeight / 2}px`,
                        sucessTop: '40%',
                        sucessMarginTop: `-${this.refs.enquirySucess.offsetHeight / 2}px`,
                    });
                } else {
                    this.setState({
                        isEnqueryFormShow: false,
                        isEnquerySucess: false,
                        message: '对不起，此次询价失败！',
                    });
                    this.setState({
                        failTop: '40%',
                        failMarginTop: `-${this.refs.enquiryFail.offsetHeight / 2}px`,
                        sucessTop: '40%',
                        sucessMarginTop: `-${this.refs.enquirySucess.offsetHeight / 2}px`,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } catch (e) {
            console.log(e);
        }
    }
    serialEnquiry = (brandId, serialId) => {
        this.setState({
            brand: brandId,
            seriesData: [],
            typeData: [],
            serial: -1,
            type: -1,
            isEnqueryFormShow: true,
        });
        const seriesData = [];
        const brandData = this.state.brandData;

        for (let el in brandData) {
            // seriesData.push(brandData[el].i);
            if (brandData[el].i.toString() === brandId.toString()) {
                this.setState({
                    seriesData: brandData[el].c,
                    serial: serialId,
                    typeData: [],
                    type: -1,
                });

                let seriesData = brandData[el].c;

                for (let dealer of seriesData) {
                    for (let serial of dealer.s) {
                        if (serial.i.toString() === serialId.toString()) {
                            this.setState({
                                typeData: serial.b,
                            });
                        }
                    }
                }
            }
        }
    };

    render() {
        const { curCity } = this.props;
        const {
            brandData,
            seriesData,
            typeData,
            brand,
            serial,
            type,
            cityData,
            province,
            city,
            cityName,
            failTop,
            failMarginTop,
            sucessTop,
            sucessMarginTop,
            serialRecommen,
            xunjiaNews,
            priceInterval,
            message,
        } = this.state;

        let sendData1 = {
            userName: this.state.user,
            mobile: this.state.tel,
            cityName: this.state.cityName,
            province: this.state.province,
            city: this.state.city,
            brandId: this.state.brand,
            serialId: this.state.serial,
            carId: this.state.type,
            carName: this.state.typeName,
            brandName: this.state.brandName,
            serialName: this.state.brandName + this.state.serialName,
            contactType: 1,
            gender: this.state.gender,
        };

        return (
            <div>
                <div className={styles.enquiry_box_cover} />
                <div
                    className={styles.enquiry_box}
                    style={{ display: this.state.isEnqueryFormShow ? 'block' : 'none' }}>
                    <div className={styles.enqimg1} />
                    <div className={styles.enqimg2} />
                    <div className={styles.tit}>
                        <div className={styles.enqimg1} />
                        咨询底价
                        <div className={styles.close} onClick={this.props.news.getChildData.bind(this, false)} />
                    </div>
                    <div className={styles.con}>
                        <div className={styles.row}>
                            <select onChange={this.brandChange.bind(this)} value={brand}>
                                <option value="-1">请选择品牌</option>
                                {brandData.map((item, index) => (
                                    <option key={index} value={item.i} name={item.n} data-name={item.n}>
                                        {item.n}
                                    </option>
                                ))}
                            </select>
                            <select onChange={this.serialChange} value={serial}>
                                <option value="-1">请选择系列</option>
                                {seriesData.map((item, index) => (
                                    <optgroup key={index} label={item.n}>
                                        {item.n}
                                        {seriesData[index].s.map((serialItem, serialIndex) => (
                                            <option key={serialIndex} value={serialItem.i} name={serialItem.n}>
                                                {serialItem.n}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </select>
                            <select id="enq_box_sel_c_1" onChange={this.typeChange} value={type}>
                                <option value="-1">请选择车型</option>
                                {typeData.map((item, index) => (
                                    <option key={index} value={item.i} name={item.n}>
                                        {item.n}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.cr_l}>
                                <p>
                                    <span className={styles.r}>*</span>
                                    <label>用户名称：</label>
                                    <input type="text" id="enq_username" onChange={this.userChange} />
                                </p>
                                {/* <p>
                                    <span className={styles.r}>*</span>
                                    <label>用户性别：</label>
                                    <input
                                        type="radio"
                                        name="enq_sex"
                                        onChange={this.genderChange}
                                        defaultChecked={true}
                                        value="0"
                                    />
                                    <span>男</span>
                                    <input type="radio" name="enq_sex" onChange={this.genderChange} value="1" />
                                    <span>女</span>
                                </p> */}
                                <p>
                                    <span className={styles.r}>*</span>
                                    <label>手机号码：</label>
                                    <input id="enq_tel" type="text" onChange={this.telChange} />
                                </p>
                                <p>
                                    <span className={styles.r}>*</span>
                                    <label>所在城市：</label>
                                    <select
                                        id="enq_province"
                                        name="province"
                                        value={province}
                                        onChange={this.provinceChange}>
                                        <option>省份/直辖市</option>
                                        {provinceData.map((item, index) => (
                                            <option key={index} value={item.code}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                    <select id="enq_city" name="city" value={city} onChange={this.cityChange}>
                                        <option value="">城市/地区</option>
                                        {cityData.map((item, index) => (
                                            <option key={index} value={item.code}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </p>
                                <div className={styles.agree}>
                                    <input
                                        type="checkbox"
                                        id="is_agree"
                                        className={styles.agreechk}
                                        onChange={this.agreeChange}
                                    />阅读并同意<a
                                        href="//auto.ifeng.com/special/pinformation/index.shtml"
                                        target="_blank"
                                        class="quc-link">
                                        {' '}
                                        个人信息保护政策{' '}
                                    </a>和<a
                                        href="//auto.ifeng.com/special/autoinformation/index.shtml"
                                        target="_blank"
                                        class="quc-link">
                                        {' '}
                                        价格咨询与活动报名个人信息保护声明{' '}
                                    </a>
                                </div>
                                <div className={styles.get_btn} onClick={this.getEnquery.bind(this)}>
                                    获取底价
                                </div>
                                <div className={styles.w_b}>
                                    * 底价将以短信的形式发送到您的手机，个人信息不会泄露给第三方
                                </div>
                            </div>
                            <div className={styles.cr_r}>
                                <img src="http://y2.ifengimg.com/auto/image/2015/1103/enquiry_4.png" />
                                <span>凤凰网汽车手机客户端</span>
                                <span className={styles.r}>买车、用车随时查</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.enquiry_b}
                    ref="enquiryFail"
                    style={{
                        display: !this.state.isEnqueryFormShow && !this.state.isEnquerySucess ? 'block' : 'none',
                        top: failTop,
                        marginTop: failMarginTop,
                    }}>
                    <div className={styles.tit}>
                        咨询底价
                        <div className={styles.close} onClick={this.props.news.getChildData.bind(this, false)}>
                            &nbsp;
                        </div>
                    </div>
                    <div className={styles.con}>
                        <div className={styles.b_tit}>对不起！询价失败！</div>
                        <div className={styles.s_tit}>
                            {message}
                            <a
                                href="javascript:void(0)"
                                target="_blank"
                                onClick={this.serialEnquiry.bind(this, -1, -1)}>
                                更多降价信息&gt;&gt;
                            </a>
                        </div>
                        <div className={styles.native}>
                            <div className={styles.nat_tit}>本地降价排行</div>
                            <ul>
                                {xunjiaNews &&
                                    xunjiaNews.map((item, index) => (
                                        <li key={index}>
                                            <i />
                                            <a target="_blank" rel={rel} href={item.url}>
                                                {item.title}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                            <div className={styles.dealer}>
                                <a href={priceInterval.url ? priceInterval.url : ''} target="_blank" rel={rel}>
                                    <img
                                        src={`//a2.ifengimg.com/autoimg/serial/180/${serial}_3.png`}
                                        width="120"
                                        height="80"
                                    />
                                </a>
                                <div className={styles.dealer_con}>
                                    <div className={styles.deal_tit}>本地推荐经销商:</div>
                                    <div className={styles.deal_name}>
                                        <a href={priceInterval.url ? priceInterval.url : ''} target="_blank" rel={rel}>
                                            {priceInterval.coName ? priceInterval.coName : ''}
                                        </a>
                                    </div>
                                    <div className={styles.deal_info}>咨询电话：{priceInterval.tel}</div>
                                </div>
                                <div className={styles.clear} />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.enquiry_b}
                    ref="enquirySucess"
                    style={{
                        display: !this.state.isEnqueryFormShow && this.state.isEnquerySucess ? 'block' : 'none',
                        top: sucessTop,
                        marginTop: sucessMarginTop,
                    }}>
                    <div className={styles.tit}>
                        咨询底价
                        <div className={styles.close} onClick={this.props.news.getChildData.bind(this, false)} />
                        <div className={styles.go_on} onClick={this.serialEnquiry.bind(this, brand, -1)}>
                            <i />
                            <span>再询一款</span>
                        </div>
                    </div>
                    <div className={styles.con}>
                        <div className={styles.succeed_tit}>{message}</div>
                        <div className={styles.succeed_s}>
                            快捷询价：
                            {serialRecommen &&
                                serialRecommen.map((item, index) => (
                                    <a
                                        key={index}
                                        href="javascript:void(0)"
                                        onClick={this.serialEnquiry.bind(this, item.brandId, item.Id)}
                                        target="_blank"
                                        rel={rel}>
                                        {item.standardName}
                                    </a>
                                ))}
                            <a
                                href="javascript:void(0)"
                                target="_blank"
                                onClick={this.serialEnquiry.bind(this, -1, -1)}
                                rel={rel}>
                                更多车型底价&gt;&gt;
                            </a>
                        </div>
                        <div className={styles.native}>
                            <div className={styles.nat_tit}>本地降价排行</div>
                            <ul>
                                {xunjiaNews &&
                                    xunjiaNews.map((item, index) => (
                                        <li key={index}>
                                            <i />
                                            <a target="_blank" rel={rel} href={item.url}>
                                                {item.title}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                            <div className={styles.dealer}>
                                <a href={priceInterval.url ? priceInterval.url : ''} target="_blank" rel={rel}>
                                    <img
                                        src={`//a2.ifengimg.com/autoimg/serial/180/${serial}_3.png`}
                                        width="120"
                                        height="80"
                                    />
                                </a>
                                <div className={styles.dealer_con}>
                                    <div className={styles.deal_tit}>本地推荐经销商:</div>
                                    <div className={styles.deal_name}>
                                        <a href={priceInterval.url ? priceInterval.url : ''} target="_blank" rel={rel}>
                                            {priceInterval.coName ? priceInterval.coName : ''}
                                        </a>
                                    </div>
                                    <div className={styles.deal_info}>咨询电话：{priceInterval.tel}</div>
                                </div>
                                <div className={styles.clear} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(EnqueryAlert);
