import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { getLargeWindAuthor, getNewCarHomeOnline, getGroupBuying } from '../../../../../services/api';
import SubTitle from '../../../components/subtitle';
import Ad from '@ifeng-fe/ui_pc_ad';
import rel from '../../../../../utils/rel';
import { handleAd } from '../../../../../utils/handleAd';
import ViewPoints from '../viewPoints';
import errorBoundary from '@ifeng-fe/errorBoundary';

class RightContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        // curCity: PropTypes.object,
        viewPoints: PropTypes.object,
        hotSubject: PropTypes.array,
    };

    constructor(...argv) {
        super(...argv);
        this.state = {
            largeWindData: [],
            newCarData: [],
            groupBuyingData: [],
            changeCount: 0,
        };
    }

    async componentDidMount() {
        // const largeWindData = await getLargeWindAuthor();
        // const newCarData = await getNewCarHomeOnline();
        // const groupBuyingData = await getGroupBuying(
        //     this.props.curCity && this.props.curCity.citycode ? this.props.curCity.citycode : 110000,
        // );

        // this.setState({
        //     largeWindData,
        //     newCarData,
        //     groupBuyingData,
        // });

        const { content } = this.props;

        const callbackFn = await handleAd(content.newcarIntroAd01);
        const callbackFn2 = await handleAd(content.newcarIntroAd04);

        callbackFn(this.refs.car_online_focus, content.newcarIntroAd01.data);
        callbackFn2(this.refs.car_online_div, content.newcarIntroAd04.data);
    }
    // 行业动态渲染
    ViewByIndustry(data) {
        const returnCarInfo = l => {
            return l.map((item, index) => {
                return (
                    <li key={index}>
                        <a href={item.url} target="_blank" rel={rel}>
                            {item.title}
                        </a>
                        <span>{item.newsTime.toString().substr(item.newsTime.length - 8, 5)}</span>
                    </li>
                );
            });
        };

        return (
            <div className={styles.mod} id="auto_p_8_4">
                <div className={styles.modinner}>
                    <div className={styles.dynamic}>
                        <SubTitle
                            content={{
                                title: '行业动态',
                                rightData: [{ name: '更多>>', url: '//auto.ifeng.com/hangye/' }],
                            }}
                        />
                        <div className={styles.mod_imgb}>
                            <ul className={styles.imgul_t}>{returnCarInfo(data)}</ul>
                            <div className={styles.clear} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    handleClickLargeWind(l) {
        const cycleNum1 = 5;
        let _largeWindData = l;
        let arr = _largeWindData.data;

        let delArr = arr.splice(0, parseInt(cycleNum1));

        _largeWindData.data = arr.concat(delArr);

        this.setState({
            largeWindData: Object.assign([], this.state.largeWindData, {
                largeWindData: _largeWindData,
            }),
        });
        console.log(this.state.newCarData.data);
    }
    // 大凤号作者渲染
    ViewBylargeWind(l) {
        // 循环得个数
        const cycleNum = 5;

        // eslint-disable-next-line array-callback-return
        return l.data.map((item, index) => {
            if (index < cycleNum) {
                return (
                    <div key={index} className={styles.largewind_item_div}>
                        <div className={styles.img_div_1}>
                            <div className={styles.first_div}>
                                <div className={styles.img_div}>
                                    <img className={styles.largeWind_img} src={item.headimg} />
                                </div>
                                <div className={styles.img_div_word}>
                                    <ul>
                                        <li className={styles.autorli}>{item.name}</li>
                                        <li className={styles.descli}>{item.description}</li>
                                    </ul>
                                </div>
                            </div>
                            <a href={item.url_new ? item.url_new : item.url} target="_blank">
                                <div className={styles.tit_div}>{item.title}</div>
                            </a>
                        </div>
                        <div className={styles.img_div}>
                            <li className={styles.articlesli}>作品数：{item.article_num}</li>
                        </div>
                    </div>
                );
            }
        });
    }

    /**
     * 渲染组件
     */
    render() {
        const { content, viewPoints, hotSubject } = this.props;

        return (
            <div className={styles.col_R}>
                <ViewPoints content={viewPoints} hotSubject={hotSubject} />
                <div className={styles.rectangle01}>
                    <Ad className={styles.rectangle01} content={content.rectangle01Ad} />
                </div>

                {/* 行业动态*/}
                {content.industryNews && content.industryNews.length > 0 ? (
                    <div>{this.ViewByIndustry(content.industryNews)}</div>
                ) : (
                    ''
                )}
                <div className={styles.rectangle02}>
                    <Ad className={styles.rectangle02} content={content.rectangle02Ad} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(RightContent);
