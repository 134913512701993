import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { rel as relText } from '../../utils/rel';
import { classnames } from '../../utils/classnames';

class TopNav extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    state = {
        subnavShow: false,
    };
    subnavTog(isShow) {
        this.setState(() => ({
            subnavShow: isShow,
        }));
    }
    // 渲染导航
    navView(list = []) {
        return list.map((item, index) => (
            <li
                key={index}
                className={item.items ? styles.last : ''}
                onMouseEnter={() => {
                    if (item.items) {
                        this.subnavTog(true);
                    }
                }}
                onMouseLeave={() => {
                    if (item.items) {
                        this.subnavTog(false);
                    }
                }}>
                <a
                    className={styles.nav_item}
                    href={item.url ? item.url : 'javascript:boid(0);'}
                    target="_blank"
                    rel={relText}>
                    {item.title}
                </a>
                {item.items ? <i /> : ''}
                {item.items ? (
                    <div className={this.state.subnavShow ? styles.subnav_box : styles.subnav_box_hide} id="navigation">
                        <ul className={classnames(styles.subnav, 'clearfix', 'subnav')}>
                            {item.items.map((liitem, liindex) => (
                                <li key={liindex}>
                                    <a
                                        href={liitem.url ? liitem.url : 'javascript:boid(0);'}
                                        target="_blank"
                                        rel={relText}>
                                        {liitem.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    ''
                )}
            </li>
        ));
    }
    render() {
        if (this.props.content) {
            return (
                <div className={classnames(styles.nav_box, 'nav_box')} id="navigation">
                    <ul className={classnames(styles.nav, 'clearfix', 'nav')}>{this.navView(this.props.content)}</ul>
                </div>
            );
        } else {
            return null;
        }
    }
}

export { TopNav };
export default TopNav;
