import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../utils/rel';

class Title extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string,
        more: PropTypes.object,
    };

    render() {
        const { content, more } = this.props;

        return (
            <div className={styles.title}>
                <div className={styles.cut} />
                <span className={styles.name}>{content}</span>
                {more ? (
                    <a href={more && more.url ? more.url : 'javascript:void(0);'} target="_blank" rel={rel}>
                        {more.name ? more.name : ''}
                    </a>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default errorBoundary(Title);
